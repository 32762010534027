import React, { FC } from 'react'

interface SafeParagraphProps {
  content?: string | null
  className?: string
}

export const SafeParagraph: FC<SafeParagraphProps> = ({
  content,
  className,
}) => {
  // HTMLエンティティにエスケープする関数
  const escapeHTML = (str: string) => {
    return str
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;')
  }

  // 改行を<br>に置き換える前にHTMLをエスケープする
  const createMarkup = () => {
    // contentがnullかundefinedの場合は空の文字列を返す
    if (content === null || content === undefined) {
      return { __html: '' }
    }

    let escapedContent = escapeHTML(content)
    // エスケープ処理後に改行文字を<br>タグに置き換える
    escapedContent = escapedContent.replace(/\r?\n/g, '<br>')
    return { __html: escapedContent }
  }

  return <p className={className} dangerouslySetInnerHTML={createMarkup()} />
}
