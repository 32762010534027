import { FC, useState } from 'react'
import { Button } from '../../components/button'
import {
  CubeControllerService,
  MemberCubeControllerService,
  MemberCubeForm,
  MemberCubesForm,
  MemberQuery,
} from '../../services'
import React from 'react'
import apiErrorHandler from '../../api/apiErrorHandler'
import { SuggestMemberCard } from '../../components/suggestMemberCard'
import { SuggestTextInput } from '../../components/suggestTextInput'
import Loading from 'react-loading'
import { toast } from 'react-toastify'

interface Props {
  memberId?: number
  closeModal: () => void
}

export const CubeSendModal: FC<Props> = ({ memberId, closeModal }) => {
  const [modalPage, setModalPage] = useState<'SELECT' | 'EXPLAIN'>('SELECT')

  const [isLoading, setIsLoading] = useState(false)
  const [inputCubeMap, setInputCubeMap] = useState<Map<number, string>>(
    new Map(),
  )
  // チャットメンバー選択と同じコンポーネントを利用しているため配列で受け取る
  const [members, setMembers] = useState<MemberQuery[]>()
  const [cubeMap, setCubeMap] = useState<Map<number, string[]>>(new Map())

  // キューブごとに説明を設定するかどうか
  const [isIndividualExplanation, setIsIndividualExplanation] = useState(false)
  // 説明（共通）
  const [giftMessage, setGiftMessage] = useState<string>('')
  // 説明（個別）
  const [giftMessageMap, setGiftMessageMap] = useState<Map<number, string[]>>(
    new Map(),
  )

  const goPage = (page: 'SELECT' | 'EXPLAIN') => {
    setModalPage(page)
  }

  // キューブ登録
  const createCubes = () => {
    if (members?.length !== 1) {
      return
    }
    const memberCubeForms = Array.from(cubeMap)
      .map(([cubeCategoryId, cubes]) => {
        return cubes.map((cubeName, index) => {
          return {
            memberId: members[0].memberId,
            cubeCategoryId: cubeCategoryId,
            cubeName: cubeName,
            giftMessage: isIndividualExplanation
              ? giftMessageMap.get(cubeCategoryId)?.[index]
              : giftMessage,
          } as MemberCubeForm
        })
      })
      .flat()
    setIsLoading(true)
    MemberCubeControllerService.createMemberCubes({
      memberCubes: memberCubeForms,
    } as MemberCubesForm)
      .then(() => {
        toast.success('登録しました')
        closeModal()
      })
      .catch(apiErrorHandler)
      .finally(() => {
        setIsLoading(false)
      })
  }

  // cube入力
  const setInput = (cubeCategoryId: number, value: string) => {
    const newInputCubeMap = new Map(inputCubeMap)
    newInputCubeMap.set(cubeCategoryId, value)
    setInputCubeMap(newInputCubeMap)
  }

  // cube追加
  const setCube = (cubeCategoryId: number, value: string) => {
    // 空文字や同名の場合は追加しない
    if (
      !value ||
      !value.match(/\S/g) ||
      cubeMap.get(cubeCategoryId)?.includes(value)
    ) {
      return
    }

    const newCubeMap = new Map(cubeMap)
    newCubeMap.set(cubeCategoryId, [
      ...(newCubeMap.get(cubeCategoryId) || []),
      value,
    ])
    setCubeMap(newCubeMap)
  }

  // cube削除
  const deleteCube = (cubeCategoryId: number, index: number) => {
    const newCubeMap = new Map(cubeMap)
    const cubes = newCubeMap.get(cubeCategoryId)
    if (cubes) {
      cubes.splice(index, 1)
      newCubeMap.set(cubeCategoryId, cubes)
      setCubeMap(newCubeMap)
    }
  }

  // サジェスト候補を取得
  const getSuggests = async (value: string): Promise<string[]> => {
    return await CubeControllerService.suggestCubes(value)
      .then(res => {
        return res.map(cube => cube.cubeName)
      })
      .catch(err => {
        apiErrorHandler(err)
        return []
      })
  }

  return (
    <div
      className="send-cube__content modal__content"
      role="dialog"
      aria-modal="true"
      aria-labelledby="send-cube__title"
    >
      <div className="modal-title_wrapper">
        <h1 className="modal-edit__title" id="send-cube__title">
          キューブを送る
        </h1>

        <Button className="btn_modal_close" onClick={closeModal}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            aria-label="Close modal"
          >
            <rect width="24" height="24" rx="12" fill="#EDF0F7" />
            <path
              d="M13.1977 11.9992L16.5496 8.6473C16.7087 8.4885 16.7982 8.27301 16.7984 8.04824C16.7986 7.82346 16.7095 7.60781 16.5507 7.44873C16.3919 7.28965 16.1764 7.20017 15.9516 7.19997C15.7268 7.19977 15.5112 7.28887 15.3521 7.44767L12.0002 10.7996L8.64828 7.44767C8.4892 7.28859 8.27344 7.19922 8.04846 7.19922C7.82349 7.19922 7.60773 7.28859 7.44865 7.44767C7.28957 7.60675 7.2002 7.82251 7.2002 8.04749C7.2002 8.27246 7.28957 8.48822 7.44865 8.6473L10.8006 11.9992L7.44865 15.3511C7.28957 15.5102 7.2002 15.726 7.2002 15.951C7.2002 16.1759 7.28957 16.3917 7.44865 16.5508C7.60773 16.7098 7.82349 16.7992 8.04846 16.7992C8.27344 16.7992 8.4892 16.7098 8.64828 16.5508L12.0002 13.1989L15.3521 16.5508C15.5112 16.7098 15.727 16.7992 15.9519 16.7992C16.1769 16.7992 16.3927 16.7098 16.5517 16.5508C16.7108 16.3917 16.8002 16.1759 16.8002 15.951C16.8002 15.726 16.7108 15.5102 16.5517 15.3511L13.1977 11.9992Z"
              fill="#8A8F9F"
            />
          </svg>
        </Button>
      </div>
      {isLoading ? (
        <Loading className="loading" type="spin" color="#007559" />
      ) : (
        <div className="send-cube__form">
          {modalPage === 'SELECT' && (
            <>
              <div className="career-edit__form">
                <div
                  className={`${memberId && members?.length === 0 && 'hidden'}`}
                >
                  <SuggestMemberCard
                    defaultMemberIds={
                      memberId
                        ? [memberId]
                        : members?.length === 1
                        ? [members[0].memberId]
                        : []
                    }
                    setMembers={setMembers}
                  />
                </div>
                {members?.length === 1 && (
                  <div className="form__item">
                    {members[0].cubeCategories.map((cubeCategory, index) => (
                      <React.Fragment key={index}>
                        <label className="form__label">
                          {cubeCategory.cubeCategoryName}
                        </label>
                        <div className="cube_input__block">
                          <SuggestTextInput
                            className="cube_input"
                            placeholder="キューブを入力"
                            value={
                              inputCubeMap.get(cubeCategory.cubeCategoryId) ||
                              ''
                            }
                            setValue={value =>
                              setInput(cubeCategory.cubeCategoryId, value)
                            }
                            onSelected={value => {
                              setCube(cubeCategory.cubeCategoryId, value)
                            }}
                            getSuggests={getSuggests}
                          />
                        </div>
                        <ul className="cube-list signup_cube__recommend-list">
                          {Array.from(cubeMap)
                            .filter(
                              ([cubeCategoryId]) =>
                                cubeCategoryId === cubeCategory.cubeCategoryId,
                            )
                            .map(([cubeCategoryId, cubes]) =>
                              cubes.map((cubeName, index) => (
                                <li className="cube" key={index}>
                                  {cubeName}
                                  <Button
                                    className="cube__delete-btn"
                                    onClick={() =>
                                      deleteCube(cubeCategoryId, index)
                                    }
                                  />
                                </li>
                              )),
                            )}
                        </ul>
                      </React.Fragment>
                    ))}
                  </div>
                )}
              </div>
              <div className="modal-edit-btn__wrapper send-cube__btns">
                <Button className="btn_secondary-l" onClick={closeModal}>
                  キャンセル
                </Button>
                <Button
                  className="btn"
                  onClick={() => {
                    goPage('EXPLAIN')
                  }}
                  disabled={members?.length !== 1 || cubeMap.size === 0}
                >
                  次へ
                </Button>
              </div>
            </>
          )}
          {modalPage === 'EXPLAIN' && (
            <>
              <div className="career-edit__form">
                <label className="form__label">説明</label>
                {/* cubeMapの値が2つ以上なら表示 */}
                {Array.from(cubeMap)
                  .map(([, cubes]) => cubes)
                  .flat().length > 1 && (
                  <div
                    className="form__item setting__detail-item"
                    style={{ marginTop: 0 }}
                  >
                    <input
                      type="checkbox"
                      id="isCommonExplanation"
                      name="isCommonExplanation"
                      checked={isIndividualExplanation}
                      onChange={() =>
                        setIsIndividualExplanation(!isIndividualExplanation)
                      }
                    />
                    <label htmlFor="isCommonExplanation">
                      キューブごとに設定する
                    </label>
                  </div>
                )}
                {isIndividualExplanation ? (
                  // キューブごとに説明を設定する場合
                  <div className="form__item">
                    {Array.from(cubeMap).map(([cubeCategoryId, cubes]) =>
                      cubes.map((cubeName, index) => (
                        <React.Fragment key={index}>
                          <label className="form__label">
                            {members
                              ? members[0]?.cubeCategories.find(
                                  category =>
                                    category.cubeCategoryId === cubeCategoryId,
                                )?.cubeCategoryName
                              : ''}
                          </label>
                          <ul className="cube-list signup_cube__recommend-list">
                            <li className="cube cube-disabled">{cubeName}</li>
                          </ul>
                          <label className="form__label">
                            <textarea
                              className="form__textarea"
                              value={
                                giftMessageMap.get(cubeCategoryId)?.[index]
                              }
                              placeholder="きっかけや理由などをコメントしましょう！（任意）"
                              onChange={e => {
                                const newExplanationMap = new Map(
                                  giftMessageMap,
                                )
                                const newExplanations =
                                  newExplanationMap.get(cubeCategoryId)
                                if (newExplanations) {
                                  newExplanations[index] = e.target.value
                                  newExplanationMap.set(
                                    cubeCategoryId,
                                    newExplanations,
                                  )
                                } else {
                                  newExplanationMap.set(cubeCategoryId, [
                                    e.target.value,
                                  ])
                                }
                                setGiftMessageMap(newExplanationMap)
                              }}
                              style={{ height: '10rem' }}
                            ></textarea>
                          </label>
                        </React.Fragment>
                      )),
                    )}
                  </div>
                ) : (
                  // 全てのキューブに共通の説明を設定する場合
                  <div className="form__item">
                    <ul className="cube-list signup_cube__recommend-list">
                      {Array.from(cubeMap).map(([, cubes]) =>
                        cubes.map((cubeName, index) => (
                          <li className="cube cube-disabled" key={index}>
                            {cubeName}
                          </li>
                        )),
                      )}
                    </ul>
                    <label className="form__label">
                      <textarea
                        className="form__textarea"
                        value={giftMessage}
                        placeholder="きっかけや理由などをコメントしましょう！（任意）"
                        onChange={e => setGiftMessage(e.target.value)}
                        style={{ height: '10rem' }}
                      ></textarea>
                    </label>
                  </div>
                )}
              </div>
              <div className="modal-edit-btn__wrapper send-cube__btns">
                <Button
                  className="btn_secondary-l"
                  onClick={() => {
                    goPage('SELECT')
                  }}
                >
                  戻る
                </Button>
                <Button
                  className="btn"
                  onClick={createCubes}
                  disabled={members?.length !== 1 || cubeMap.size === 0}
                >
                  キューブを送る
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}
