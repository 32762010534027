import React from 'react'
import { NavLink } from 'react-router-dom'

interface Props {
  closeModal: () => void
}

export const DataOptionsModal: React.FC<Props> = ({ closeModal }) => {
  const handleNavLinkClick = () => {
    closeModal()
  }
  return (
    <div className="data-options-modal">
      <ul className="sidenav-list__item">
        {[
          {
            to: '/cube-summary',
            text: 'サマリー',
            icon: '/images/icon_data.svg',
          },
          {
            to: '/ranking',
            text: 'ランキング',
            icon: '/images/icon_ranking.svg',
          },
        ].map((item, index) => (
          <li key={index}>
            <NavLink
              to={item.to}
              className="data-options__link"
              onClick={handleNavLinkClick}
            >
              <img src={item.icon} alt="icon" className="data-options__icon" />
              {item.text}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  )
}
