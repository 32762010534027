import React, { FC, useState } from 'react'
import { Head } from '../../layouts/head'
import { Header } from '../../layouts/header'
import { Footer } from '../../layouts/footer'
import { HeaderType } from '../../enums/HeaderType'
import { useNavigate } from 'react-router-dom'
import env from '../../config/env'
import axios from 'axios'
import { Button } from '../../components/button'

export const LoginMail: FC = () => {
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search)
  const [passwordResetResult, setPasswordResetResult] = useState(
    queryParams.get('passwordResetResult') as string,
  )

  const [loginForm, setLoginForm] = useState({
    email: '',
    password: '',
  })

  const [loginErrorMessage, setLoginErrorMessage] = useState('')
  const [loginErrorMessage2, setLoginErrorMessage2] = useState('')

  const [privacyPolicy, setPrivacyPolicy] = useState(false)

  const togglePrivacyPolicy = () => {
    setPrivacyPolicy(!privacyPolicy)
  }

  const signInWithMail = () => {
    if (!privacyPolicy) return
    setPasswordResetResult('')
    setLoginErrorMessage('')

    const params = new URLSearchParams()
    params.append('username', loginForm.email)
    params.append('password', loginForm.password)

    axios
      .post(`${env.BACKEND_BASE_URL}/api/sign-in`, params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        withCredentials: true,
      })
      .then(res => {
        navigate('/')
        console.log(res)
      })
      .catch(() => {
        setLoginErrorMessage('メールアドレスまたはパスワードが間違っています。')
        if (loginErrorMessage) {
          setLoginErrorMessage2(
            'ブラウザのキャッシュをクリアして再度お試しください。',
          )
        }
      })
  }

  return (
    <>
      <Head />
      <Header headerType={HeaderType.NOT_LOGIN} />
      <main className="main">
        <div className="form__wrapper">
          <h1 className="pagetitle">ログイン</h1>

          <hr className="separator" />

          <p className="form__check form__check-center">
            <input
              type="checkbox"
              id="privacy-policy"
              name="privacy-policy"
              onChange={togglePrivacyPolicy}
              checked={privacyPolicy}
            />
            <label htmlFor="privacy-policy">
              プライバシーポリシーに同意する
            </label>
          </p>
          <p className="form__link signup">
            <a href="/privacy-policy" target="_blank">
              プライバシーポリシー
            </a>
          </p>

          <hr className="separator" />

          {passwordResetResult === 'success' && (
            <>
              <p className="form__item">
                <span className="form__success">
                  パスワードをリセットしました
                </span>
              </p>
              <hr className="separator" />
            </>
          )}

          {loginErrorMessage && (
            <>
              <p className="form__item">
                <span className="form__error">{loginErrorMessage}</span>
                <span className="form__error">{loginErrorMessage2}</span>
              </p>
              <hr className="separator" />
            </>
          )}

          <p className="form__item">
            <label className="form__label">
              メールアドレス
              <input
                type="email"
                name="email"
                onChange={e =>
                  setLoginForm({ ...loginForm, email: e.target.value })
                }
              />
            </label>
          </p>

          <p className="form__item">
            <label className="form__label">
              パスワード
              <input
                type="password"
                name="password"
                onChange={e =>
                  setLoginForm({ ...loginForm, password: e.target.value })
                }
              />
            </label>
          </p>

          <div className="custom-login-button">
            <Button className="btn" onClick={signInWithMail}>
              ログイン
            </Button>
          </div>

          <div className="custom-mail-link">
            <a onClick={() => navigate('/password-reset-mail')}>
              パスワードを忘れた方はこちら
            </a>
          </div>
          <div className="custom-mail-link">
            <a onClick={() => navigate('/login')}>
              ソーシャルアカウントでログイン
            </a>
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
}
