import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  MemberCubeQuery,
  SimpleMemberQuery,
  TimelineCardQuery,
} from '../../services'
import TextInputWithButton from '../TextInputWithButton'

interface GiftUpdateCubeCardProps {
  timelineCard: TimelineCardQuery
  index: number
  member: SimpleMemberQuery
  suffix: string
  giftMember: SimpleMemberQuery
  onSendMessage: (message: string) => void
  children: React.ReactNode
}

const GiftUpdateCubeCard: React.FC<GiftUpdateCubeCardProps> = ({
  timelineCard,
  member,
  giftMember,
  index,
  suffix,
  onSendMessage,
  children,
}) => {
  const navigate = useNavigate()
  // キューブをカテゴリーごとにグルーピング
  const cubeMap = new Map<string, MemberCubeQuery[]>()
  timelineCard.updateCubeCard?.linkMemberCubes?.forEach(cube => {
    const cubeCategoryName = cube.cubeCategory.cubeCategoryName
    if (cubeMap.has(cubeCategoryName)) {
      cubeMap.get(cubeCategoryName)?.push(cube)
    } else {
      cubeMap.set(cubeCategoryName, [cube])
    }
  })

  // メッセージをグルーピング
  const messageMap = new Map<string | undefined, number[]>()
  timelineCard.updateCubeCard?.messages.forEach(messageQuery => {
    const message = messageQuery.message
    if (messageMap.has(message)) {
      const memberCubeIds = messageMap.get(message)
      if (memberCubeIds) {
        memberCubeIds.push(messageQuery.memberCubeId)
        messageMap.set(message, memberCubeIds)
      }
    } else {
      messageMap.set(message, [messageQuery.memberCubeId])
    }
  })

  return (
    <li className="home-timeline__card home-timeline__card-cube" key={index}>
      <Link
        className="home-timeline__card-link"
        to={`/member-detail/${member.memberId}`}
      >
        <div className="home-timeline__send-cube">
          <div
            className="home-timeline__card-upper-avater"
            onClick={event => {
              event.preventDefault()
              event.stopPropagation()
              window.location.href = `/member-detail/${member.memberId}`
            }}
          >
            <img
              src={
                giftMember.profileImageUrl
                  ? giftMember.profileImageUrl
                  : '/icons/avatar-sample.png'
              }
              alt="プロフィール画像"
              width={40}
              height={40}
            />
          </div>
          <svg
            width="6"
            height="4"
            viewBox="0 0 6 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="0.499756" width="5" height="4" rx="2" fill="#C9CFD6" />
          </svg>
          <div className="home-timeline__card-cube_icon-send">
            <img
              src={'/images/icon_cube_home-send.svg'}
              alt="プロフィール画像"
              width={15}
              height={14}
            />
          </div>
          <svg
            width="6"
            height="4"
            viewBox="0 0 6 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="0.499756" width="5" height="4" rx="2" fill="#C9CFD6" />
          </svg>
          <div className="home-timeline__card-cube_icon-cube">
            <img
              src={'/images/icon_cube_home.svg'}
              alt="プロフィール画像"
              width={60}
              height={60}
            />
          </div>
          <svg
            width="6"
            height="4"
            viewBox="0 0 6 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="0.499756" width="5" height="4" rx="2" fill="#C9CFD6" />
          </svg>
          <div className="home-timeline__card-cube_icon-send">
            <img
              src={'/images/icon_cube_home-send.svg'}
              alt="プロフィール画像"
              width={15}
              height={14}
            />
          </div>
          <svg
            width="6"
            height="4"
            viewBox="0 0 6 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="0.499756" width="5" height="4" rx="2" fill="#C9CFD6" />
          </svg>
          <div className="home-timeline__card-upper-avater">
            <img
              src={
                member.profileImageUrl
                  ? member.profileImageUrl
                  : '/icons/avatar-sample.png'
              }
              alt="プロフィール画像"
              width={40}
              height={40}
            />
          </div>
        </div>
        <div className="home-timeline__card-upper-content">
          <h2 className="home-timeline__card-upper-title">
            <span
              className="clickable-name"
              onClick={event => {
                event.preventDefault()
                event.stopPropagation()
                navigate(`/member-detail/${giftMember.memberId}`)
              }}
            >
              {giftMember.memberName}
            </span>
            さんが
            <span
              className="clickable-name"
              onClick={event => {
                event.preventDefault()
                event.stopPropagation()
                window.location.href = `/member-detail/${member.memberId}`
              }}
            >
              {member.memberName}
            </span>
            さんに{suffix}
          </h2>
          <span className="home-timeline__card-posttime">
            {timelineCard.createdAt}
          </span>
          {children}
        </div>
      </Link>
      <TextInputWithButton
        onSend={onSendMessage}
        placeholder={`${member.memberName}さんへのコメント`}
      />
    </li>
  )
}

export default GiftUpdateCubeCard
