import React, { FC, useEffect, useState } from 'react'
import {
  AdminControllerService,
  CommonControllerService,
  CreateMembersForm,
  MemberForm,
  MemberRoleMap,
  MemberRoleQuery,
} from '../../services'
import apiErrorHandler from '../../api/apiErrorHandler'
import { toast } from 'react-toastify'
import { Button } from '../../components/button'

interface Props {
  closeModal: () => void
}

export const MemberSaveModal: FC<Props> = ({ closeModal }) => {
  const [memberRoleMaps, setMemberRoleMaps] = useState<MemberRoleMap[]>([])

  const [inputValue, setInputValue] = useState<string>('')
  const [memberRole, setMemberRole] = useState<MemberRoleQuery.memberRole>(
    MemberRoleQuery.memberRole.MEMBER,
  )
  const [emails, setEmails] = useState<string[]>([])

  useEffect(() => {
    CommonControllerService.getMemberRoles()
      .then(res => {
        setMemberRoleMaps(res)
      })
      .catch(err => {
        apiErrorHandler(err)
      })
  }, [])

  const update = () => {
    if (emails.length === 0) return

    AdminControllerService.createMembers({
      members: emails.map(email => {
        return {
          email: email,
          memberRole: memberRole,
        } as MemberForm
      }),
    } as CreateMembersForm)
      .then(res => {
        // 1人でも登録できた場合（一部のメンバーの招待に失敗した場合）
        if (res.length !== emails.length) {
          toast.success('メンバーを招待しました')
        }
        // 登録できなかったメールアドレスが存在する場合
        if (res.length !== 0) {
          toast.warn(
            <div>
              <p>以下のメンバーの招待に失敗しました。</p>
              <p>招待済みの可能性があります。</p>
              <ul>
                {res.map(email => (
                  <li key={email}>{email}</li>
                ))}
              </ul>
            </div>,
          )
        }
        closeModal()
      })
      .catch(err => {
        apiErrorHandler(err)
      })
  }

  const deleteEmail = (index: number) => {
    emails?.splice(index, 1)
    setEmails([...emails])
  }

  const extractEmails = () => {
    // メールアドレス形式の文字列を抽出
    const newEmails = inputValue
      .split(/[,、]/) // カンマ区切りで分割
      .filter(email =>
        /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/.test(
          email,
        ),
      ) // メールアドレス形式の文字列のみ抽出
      .map(email => email.trim()) // 前後の空白を削除
      .filter((email, index, self) => self.indexOf(email) === index) // 重複を削除
      .filter(email => !emails.includes(email)) // 既に登録されているメールアドレスを除外
    setEmails([...emails, ...newEmails])
    setInputValue('')
  }

  return (
    <div
      className="contact-info-edit__content"
      role="dialog"
      aria-modal="true"
      aria-labelledby="contact-info-edit__title"
    >
      <div className="modal-title_wrapper">
        <h1 className="modal-edit__title" id="contact-info-edit__title">
          メンバーを招待
        </h1>

        <Button className="btn_modal_close" onClick={closeModal}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            aria-label="Close modal"
          >
            <rect width="24" height="24" rx="12" fill="#EDF0F7" />
            <path
              d="M13.1977 11.9992L16.5496 8.6473C16.7087 8.4885 16.7982 8.27301 16.7984 8.04824C16.7986 7.82346 16.7095 7.60781 16.5507 7.44873C16.3919 7.28965 16.1764 7.20017 15.9516 7.19997C15.7268 7.19977 15.5112 7.28887 15.3521 7.44767L12.0002 10.7996L8.64828 7.44767C8.4892 7.28859 8.27344 7.19922 8.04846 7.19922C7.82349 7.19922 7.60773 7.28859 7.44865 7.44767C7.28957 7.60675 7.2002 7.82251 7.2002 8.04749C7.2002 8.27246 7.28957 8.48822 7.44865 8.6473L10.8006 11.9992L7.44865 15.3511C7.28957 15.5102 7.2002 15.726 7.2002 15.951C7.2002 16.1759 7.28957 16.3917 7.44865 16.5508C7.60773 16.7098 7.82349 16.7992 8.04846 16.7992C8.27344 16.7992 8.4892 16.7098 8.64828 16.5508L12.0002 13.1989L15.3521 16.5508C15.5112 16.7098 15.727 16.7992 15.9519 16.7992C16.1769 16.7992 16.3927 16.7098 16.5517 16.5508C16.7108 16.3917 16.8002 16.1759 16.8002 15.951C16.8002 15.726 16.7108 15.5102 16.5517 15.3511L13.1977 11.9992Z"
              fill="#8A8F9F"
            />
          </svg>
        </Button>
      </div>

      <div className="save-cube__form">
        <div className="form__item">
          <label className="form__label">
            メールアドレス
            <input
              type="email"
              name="email"
              placeholder="カンマ区切りで複数入力"
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
              // enterキーで入力値を抽出
              onKeyDown={e => e.keyCode === 13 && extractEmails()}
              onBlur={extractEmails}
            />
          </label>
        </div>
        <hr className="separator" />
        <div className="form__item">
          <label className="form__label">権限</label>
          <div className="form__select-wrapper">
            <select
              className="form__select form__role-select"
              value={memberRole}
              onChange={e =>
                setMemberRole(e.target.value as MemberRoleQuery.memberRole)
              }
            >
              {memberRoleMaps.map((memberRoleMap, index) => (
                <option key={index} value={memberRoleMap.name}>
                  {memberRoleMap.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form__item">
          <label className="form__label">招待するメンバー</label>
          <div className="form-multiselect__field">
            {emails.map((email, index) => (
              <span className="cube" key={index}>
                {email}
                <Button
                  className="cube__delete-btn"
                  onClick={() => deleteEmail(index)}
                />
              </span>
            ))}
          </div>
        </div>

        <div className="modal-edit-btn__wrapper contact-info-edit-btn__wrapper">
          <Button
            className="btn_secondary-l btn_modal-edit_cancel"
            onClick={closeModal}
          >
            キャンセル
          </Button>

          <Button className="btn btn_modal-edit_save" onClick={update}>
            招待
          </Button>
        </div>
      </div>
    </div>
  )
}
