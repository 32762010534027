import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import apiErrorHandler from '../../api/apiErrorHandler'
import {
  CubeControllerService,
  CubeDetailQuery,
  MemberControllerService,
  MemberCubeControllerService,
  MemberQuery,
} from '../../services'
import Loading from 'react-loading'
import { Button } from '../../components/button'
import { toast } from 'react-toastify'
import { useModal } from '../../contexts/modalContext'
import { CubeEditModal } from '../cubeEditModal'
import { GiftMessageAddModal } from '../giftMessageAddModal'

interface Props {
  cubeId?: number
  memberCubeId?: number
  deleteButtonEnabled?: boolean
  yearMonth?: string
  closeModal: () => void
}
export const CubeDetailModal: FC<Props> = ({
  cubeId,
  memberCubeId,
  deleteButtonEnabled,
  yearMonth,
  closeModal,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [cubeDetail, setCubeDetail] = useState({} as CubeDetailQuery)
  const [member, setMember] = useState({} as MemberQuery)
  const navigate = useNavigate()
  const { openModal } = useModal()

  useEffect(() => {
    // ランキングのモーダルの場合
    cubeId &&
      CubeControllerService.getCubeDetail(cubeId, yearMonth)
        .then(res => {
          setCubeDetail(res)
          setIsLoading(false)
        })
        .catch(apiErrorHandler)

    // キューブのモーダルの場合
    memberCubeId &&
      MemberCubeControllerService.getMemberCubeDetail(memberCubeId)
        .then(res => {
          setCubeDetail({
            ...res,
            // キューブ保持者の情報を削除
            otherMembers: res.otherMembers.filter(
              target => target.memberId !== res.memberId,
            ),
          })
          setIsLoading(false)
        })
        .catch(apiErrorHandler)

    // ログインユーザーの情報を取得
    MemberControllerService.getMember(0)
      .then(res => {
        setMember(res)
      })
      .catch(apiErrorHandler)
  }, [closeModal])

  const deleteCube = () => {
    // 確認
    if (!window.confirm('このキューブを削除しますか？')) return
    memberCubeId &&
      MemberCubeControllerService.deleteMemberCube(memberCubeId)
        .then(() => {
          toast.success('キューブを削除しました')
          closeModal()
        })
        .catch(apiErrorHandler)
  }

  return (
    <div
      className="cube-edit__content"
      role="dialog"
      aria-modal="true"
      aria-labelledby="cube-edit__title"
    >
      {isLoading ? (
        <Loading className="loading" type="spin" color="#007559" />
      ) : (
        <>
          <div className="modal-title_wrapper">
            <h1 className="modal-edit__title" id="cube-edit__title">
              キューブの詳細
            </h1>

            <Button className="btn_modal_close" onClick={closeModal}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                aria-label="Close modal"
              >
                <rect width="24" height="24" rx="12" fill="#EDF0F7" />
                <path
                  d="M13.1977 11.9992L16.5496 8.6473C16.7087 8.4885 16.7982 8.27301 16.7984 8.04824C16.7986 7.82346 16.7095 7.60781 16.5507 7.44873C16.3919 7.28965 16.1764 7.20017 15.9516 7.19997C15.7268 7.19977 15.5112 7.28887 15.3521 7.44767L12.0002 10.7996L8.64828 7.44767C8.4892 7.28859 8.27344 7.19922 8.04846 7.19922C7.82349 7.19922 7.60773 7.28859 7.44865 7.44767C7.28957 7.60675 7.2002 7.82251 7.2002 8.04749C7.2002 8.27246 7.28957 8.48822 7.44865 8.6473L10.8006 11.9992L7.44865 15.3511C7.28957 15.5102 7.2002 15.726 7.2002 15.951C7.2002 16.1759 7.28957 16.3917 7.44865 16.5508C7.60773 16.7098 7.82349 16.7992 8.04846 16.7992C8.27344 16.7992 8.4892 16.7098 8.64828 16.5508L12.0002 13.1989L15.3521 16.5508C15.5112 16.7098 15.727 16.7992 15.9519 16.7992C16.1769 16.7992 16.3927 16.7098 16.5517 16.5508C16.7108 16.3917 16.8002 16.1759 16.8002 15.951C16.8002 15.726 16.7108 15.5102 16.5517 15.3511L13.1977 11.9992Z"
                  fill="#8A8F9F"
                />
              </svg>
            </Button>
          </div>

          <div className="modal-cube__wrapper">
            <h2 className="modal-edit__sub-title cube-category__sub-title">
              {cubeDetail.cubeCategory?.cubeCategoryName}
            </h2>
            <Button
              className={
                cubeDetail.giftMembers[0]?.memberId === cubeDetail.memberId
                  ? 'cube_plus_timeline_sent'
                  : 'cube_plus_timeline'
              }
              disabled
            >
              <span className="cube_plus_timeline__text-wrapper">
                <span className="cube_plus_timeline__text">
                  {cubeDetail.cubeName}
                </span>
              </span>
              <span className="cube_plus_timeline__add">
                <svg
                  className="cube__reaction_icon inactive"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clipPath)">
                    <circle
                      className="cube__reaction_icon__circle"
                      cx="8"
                      cy="8.5"
                      r="8"
                    />
                    <path
                      className="cube__reaction_icon__heart"
                      d="M5.45373 4.57065C5.90333 4.49404 6.36439 4.51931 6.80292 4.64459C7.24145 4.76988 7.64627 4.99198 7.98755 5.29452L8.00635 5.31129L8.02362 5.29605C8.34934 5.0102 8.73227 4.7971 9.14686 4.67096C9.56145 4.54482 9.99818 4.50855 10.4279 4.56455L10.5529 4.58284C11.0945 4.67636 11.6008 4.91463 12.0181 5.27241C12.4354 5.63019 12.7482 6.09416 12.9233 6.61519C13.0985 7.13623 13.1295 7.69493 13.013 8.23212C12.8965 8.76932 12.6369 9.26502 12.2617 9.66674L12.1703 9.76071L12.1459 9.78154L8.36143 13.5299C8.27409 13.6164 8.15839 13.6682 8.03575 13.6759C7.91311 13.6836 7.79186 13.6465 7.69445 13.5716L7.6467 13.5299L3.84038 9.7597C3.43715 9.36736 3.15038 8.87107 3.01183 8.32579C2.87329 7.78052 2.88836 7.20753 3.0554 6.6703C3.22243 6.13307 3.53491 5.65255 3.95821 5.28196C4.38151 4.91137 4.89913 4.66518 5.45373 4.57065Z"
                    />
                  </g>
                </svg>
              </span>
              {cubeDetail.giftMembers?.length > 1 && (
                <span className="cube_plus_timeline__count">
                  {cubeDetail.giftMembers?.length}
                </span>
              )}
            </Button>
          </div>

          <div className="gift-message__block">
            <h2
              className={`modal-edit__sub-title gift-message__sub-title ${
                memberCubeId ? 'gift-message__plus' : ''
              }`}
              onClick={event => {
                if (memberCubeId) {
                  event.preventDefault()
                  event.stopPropagation()
                  openModal(GiftMessageAddModal, {
                    memberId: cubeDetail.memberId,
                    cubeCategoryId: cubeDetail.cubeCategory.cubeCategoryId,
                    cubeName: cubeDetail.cubeName,
                  })
                }
              }}
            >
              キューブについて
            </h2>
            <ul className="gift-message__list">
              {cubeDetail.giftCubes?.length === 0 && (
                <li
                  className="gift-message__item"
                  onClick={event => {
                    event.preventDefault()
                    event.stopPropagation()
                    openModal(GiftMessageAddModal, {
                      memberId: cubeDetail.memberId,
                      cubeCategoryId: cubeDetail.cubeCategory.cubeCategoryId,
                      cubeName: cubeDetail.cubeName,
                    })
                  }}
                >
                  <p className="gift-message__text">
                    このキューブに説明を追加しよう！
                  </p>
                </li>
              )}
              {cubeDetail.giftCubes?.map((giftCube, index) => (
                <li
                  className="gift-message__item"
                  key={index}
                  onClick={event => {
                    if (
                      memberCubeId &&
                      (giftCube.giftMemberId === member.memberId ||
                        cubeDetail.memberId === member.memberId)
                    ) {
                      event.preventDefault()
                      event.stopPropagation()
                      openModal(CubeEditModal, {
                        memberCubeGiftMemberId: giftCube.memberCubeGiftMemberId,
                        memberCubeId: memberCubeId,
                        giftMemberId: giftCube.giftMemberId,
                        giftMessage: giftCube.giftMessage,
                        isGiftMember: giftCube.giftMemberId === member.memberId,
                      })
                    }
                  }}
                >
                  <p
                    className={`gift-message__name ${
                      memberCubeId &&
                      (giftCube.giftMemberId === member.memberId ||
                        cubeDetail.memberId === member.memberId)
                        ? 'gift-message__edit'
                        : ''
                    }`}
                  >
                    {giftCube.giftMemberName}
                  </p>
                  <p className="gift-message__text">{giftCube.giftMessage}</p>
                </li>
              ))}
            </ul>
          </div>

          <div className="good-members__block">
            <h2 className="modal-edit__sub-title good-members__sub-title">
              このキューブを送ったメンバー
            </h2>

            <ul className="cube-edit__list">
              {cubeDetail.giftMembers
                ?.filter(
                  // giftMembersの重複を削除
                  (member, index, self) =>
                    self.findIndex(
                      target => target.memberId === member.memberId,
                    ) === index,
                )
                ?.map((member, index) => (
                  <li className="cube-edit-list__item" key={index}>
                    <div
                      className="avatar__block"
                      onClick={event => {
                        event.preventDefault()
                        event.stopPropagation()
                        window.location.href = `/member-detail/${member.memberId}`
                        closeModal()
                      }}
                    >
                      <div className="avatar__img">
                        <img
                          src={
                            member.profileImageUrl
                              ? member.profileImageUrl
                              : '/icons/avatar-sample.png'
                          }
                          alt="プロフィール画面"
                        />
                      </div>
                      <div className="avatar-block-name__wrapper">
                        <p className="avatar_name">{member.memberName}</p>
                        <span className="avatar-block-name__number">
                          {
                            cubeDetail.giftMembers.filter(
                              target => target.memberId === member.memberId,
                            ).length
                          }
                        </span>
                      </div>
                      <p className="avatar_position">
                        {member.position?.positionName}
                      </p>
                    </div>
                  </li>
                ))}
            </ul>
          </div>

          <div className="other-members__block">
            <div className="other-members__block-title">
              <h2 className="modal-edit__sub-title modal-edit__sub-title-others">
                このキューブがついている{memberCubeId && '他の'}メンバー
              </h2>
              {cubeDetail.otherMembers?.length > 5 && (
                <div className="other-members__block-btn">
                  <Button
                    className="btn_text"
                    onClick={event => {
                      event.preventDefault()
                      event.stopPropagation()
                      window.location.href = `/member-list?keyword=${cubeDetail.cubeName}&cubeIds=${cubeDetail.cubeId}&ai=true`
                    }}
                  >
                    もっとみる
                  </Button>
                </div>
              )}
            </div>

            <ul className="cube-edit__list">
              {cubeDetail.otherMembers?.slice(0, 5).map((member, index) => (
                <li className="cube-edit-list__item" key={index}>
                  <div
                    className="avatar__block"
                    onClick={event => {
                      event.preventDefault()
                      event.stopPropagation()
                      navigate(`/member-detail/${member.memberId}`)
                      closeModal()
                    }}
                  >
                    <div className="avatar__img">
                      <img
                        src={
                          member.profileImageUrl
                            ? member.profileImageUrl
                            : '/icons/avatar-sample.png'
                        }
                        alt="プロフィール画面"
                      />
                    </div>
                    <p className="avatar_name">{member.memberName}</p>
                    <p className="avatar_position">
                      {member.position?.positionName}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          {memberCubeId && (
            <div
              className="cube-edit_delete-btn__wrapper"
              style={{
                // TODO リリースまで非表示
                display: 'none',
              }}
            >
              <Button
                className="btn chat__submit-btn"
                onClick={() => {
                  // TODO タイムライン詳細へ遷移
                }}
              >
                みんなのコメントを見る
              </Button>
            </div>
          )}

          {memberCubeId && deleteButtonEnabled && (
            <button
              className="btn cube-delete-btn"
              onClick={event => {
                event.preventDefault()
                event.stopPropagation()
                deleteCube()
              }}
            >
              キューブを削除する
            </button>
          )}
        </>
      )}
    </div>
  )
}
