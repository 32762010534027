import React from 'react'
import { MemberQuery } from '../../services'
import RecommendMemberCard from '../MemberCard/RecommendMemberCard'

interface PickupMemberProps {
  recommendRecentMembers: MemberQuery[]
  recommendCommonMembers: MemberQuery[]
}

// recommendMembersからランダムで1つ取り出す
const getRandomMember = (members: MemberQuery[]): MemberQuery | null => {
  if (members.length === 0) return null
  const randomIndex = Math.floor(Math.random() * members.length)
  return members[randomIndex]
}

export const PickupMember: React.FC<PickupMemberProps> = ({
  recommendRecentMembers,
  recommendCommonMembers,
}) => {
  const recommendMembers = [
    ...recommendRecentMembers,
    ...recommendCommonMembers,
  ]
  const pickupMember = getRandomMember(recommendMembers)
  if (!pickupMember) return null

  return (
    <section className="home-sidebar__section home-sidebar__recommend">
      <h3 className="home-sidebar__section-name">あなたにおすすめのメンバー</h3>
      <RecommendMemberCard member={pickupMember} />
    </section>
  )
}
