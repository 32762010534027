import React from 'react'
import ReactDOM from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import BodyClassName from 'react-body-classname'
import { ModalProvider } from './contexts/modalContext'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { Complete } from './pages/complete'
import { Home } from './pages/home'
import { Login } from './pages/login'
import { LoginMail } from './pages/loginMail'
import { MemberDetail } from './pages/memberDetail'
// 現在使用していないページを全てコメントアウト
// import { Modal } from './pages/modal'
// import { PasswordCompleted } from './pages/passwordCompleted'
// import { PasswordEnter } from './pages/passwordEnter'
// import { PasswordMail } from './pages/passwordMail'
import { PasswordResetMail } from './pages/passwordResetMail'
import { Profile } from './pages/profile'
// import { Questionnaire } from './pages/questionnaire'
// import { QuestionnairePartner } from './pages/questionnairePartner'
// import { QuestionnaireRecommend } from './pages/questionnaireRecommend'
import { SearchInput } from './pages/searchInput'
import { Setting } from './pages/setting'
import { Signup } from './pages/signup'
import { SignupCube } from './pages/signupCube'
import { SignupProfile } from './pages/signupProfile'

import init from './init'

import 'destyle.css/destyle.css'
import './scss/style.scss'
import 'react-toastify/dist/ReactToastify.css'
import env from './config/env'
import { setUserProperties, getAnalytics } from 'firebase/analytics'
import { MemberControllerService } from './services'
import { ToastContainer } from 'react-toastify'
import PrivacyPolicy from './pages/privacyPolicy'
import { Registration } from './pages/registration'
import { Member } from './pages/member'
import { ChatList } from './pages/chat'
import { ChatDetail } from './pages/chat/detail'
import { RecoilRoot } from 'recoil'
import { Ranking } from './pages/ranking'
import { CubeSummary } from './pages/cubeSummary'
import { RedirectToTalkDetail } from './pages/redirectToTalkDetail'
import { MemberRegistration } from './pages/memberRegistration'
import { PasswordReset } from './pages/passwordReset'
import { NotFoundPage } from './pages/notFoundPage'
import { Notification } from './pages/notification'
import { RecommendEmail } from './pages/recommendEmail'
import { RecommendEmailFromCube } from './pages/recommendEmailFromCube'

init()

const root = ReactDOM.createRoot(document.getElementById('app') as HTMLElement)

MemberControllerService.getLoggedInMember().then(loggedInMember => {
  const isUserLoggedIn = !!loggedInMember.memberId
  if (env.FIREBASE_API_KEY) {
    const analytics = getAnalytics()
    // 画面の情報が取得できていないことがあるので1秒遅延を入れる
    setTimeout(() => {
      setUserProperties(analytics, {
        workspaceId: loggedInMember.workspaceId,
        userId: loggedInMember.memberId,
      })
    }, 1000)
  }
  // todo hooks化
  const currentPage = window.location.pathname
  /**
   * ログイン不要ページ
   */
  const loginNotRequiredPaths = [
    '/login',
    '/login/mail',
    '/password-reset-mail',
    '/password-reset',
    '/member/registration',
  ]

  const developmentPaths = ['/sample']

  const ignorePaths = ['/privacy-policy']

  if (process.env.REACT_APP_ENV === 'development') {
    loginNotRequiredPaths.push(...developmentPaths)
  }

  if (
    !isUserLoggedIn &&
    loginNotRequiredPaths.every(path => path !== currentPage) &&
    ignorePaths.every(path => path !== currentPage)
  ) {
    window.location.href = '/login'
  }

  // 開発用のパスを無効化
  if (process.env.REACT_APP_ENV !== 'development') {
    if (developmentPaths.some(path => path === currentPage)) {
      window.location.href = '/'
    }
  }

  root.render(
    <React.StrictMode>
      <HelmetProvider>
        <BrowserRouter>
          <RecoilRoot>
            <ModalProvider>
              <Routes>
                <Route
                  path="/complete"
                  element={
                    <BodyClassName className="complete_page">
                      <Complete />
                    </BodyClassName>
                  }
                />
                <Route
                  path="/"
                  element={
                    <BodyClassName className="home">
                      <Home />
                    </BodyClassName>
                  }
                />
                <Route
                  path="/login"
                  element={
                    <BodyClassName className="">
                      <Login />
                    </BodyClassName>
                  }
                />
                <Route
                  path="/login/mail"
                  element={
                    <BodyClassName className="">
                      <LoginMail />
                    </BodyClassName>
                  }
                />
                <Route
                  path="/member/registration"
                  element={
                    <BodyClassName className="">
                      <MemberRegistration />
                    </BodyClassName>
                  }
                />
                <Route
                  path="/member-list"
                  element={
                    <BodyClassName className="search_input">
                      <SearchInput />
                    </BodyClassName>
                  }
                />
                <Route
                  path="/member-detail/:memberId"
                  element={
                    <BodyClassName className="member_details_page">
                      <MemberDetail />
                    </BodyClassName>
                  }
                />
                {/* <Route
                path="/modal"
                element={
                  <BodyClassName className="">
                    <Modal />
                  </BodyClassName>
                }
              /> */}
                {/* <Route
                path="/password-completed"
                element={
                  <BodyClassName className="">
                    <PasswordCompleted />
                  </BodyClassName>
                }
              />
              <Route
                path="/password-enter"
                element={
                  <BodyClassName className="">
                    <PasswordEnter />
                  </BodyClassName>
                }
              /> */}
                {/* <Route
                path="/password-mail"
                element={
                  <BodyClassName className="">
                    <PasswordMail />
                  </BodyClassName>
                }
              /> */}
                <Route
                  path="/password-reset-mail"
                  element={
                    <BodyClassName className="">
                      <PasswordResetMail />
                    </BodyClassName>
                  }
                />
                <Route
                  path="/password-reset"
                  element={
                    <BodyClassName className="">
                      <PasswordReset />
                    </BodyClassName>
                  }
                />
                <Route
                  path="/privacy-policy"
                  element={
                    <BodyClassName className="">
                      <PrivacyPolicy />
                    </BodyClassName>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <BodyClassName className="profile_page">
                      <Profile />
                    </BodyClassName>
                  }
                />
                {/* <Route
                path="/questionnaire"
                element={
                  <BodyClassName className="">
                    <Questionnaire />
                  </BodyClassName>
                }
              />
              <Route
                path="/questionnaire-partner"
                element={
                  <BodyClassName className="">
                    <QuestionnairePartner />
                  </BodyClassName>
                }
              />
              <Route
                path="/questionnaire-recommend"
                element={
                  <BodyClassName className="">
                    <QuestionnaireRecommend />
                  </BodyClassName>
                }
              /> */}
                <Route
                  path="/setting"
                  element={
                    <BodyClassName className="setting">
                      <Setting />
                    </BodyClassName>
                  }
                />
                <Route
                  path="/signup"
                  element={
                    <BodyClassName className="">
                      <Signup />
                    </BodyClassName>
                  }
                />
                <Route
                  path="/sign-up-cube"
                  element={
                    <BodyClassName className="">
                      <SignupCube />
                    </BodyClassName>
                  }
                />
                <Route
                  path="/sign-up"
                  element={
                    <BodyClassName className="">
                      <SignupProfile />
                    </BodyClassName>
                  }
                />
                {/* TODO リリースまで非表示
                <Route
                  path="/timeline-detail"
                  element={
                    <BodyClassName className="">
                      <TimelineDetail />
                    </BodyClassName>
                  }
                />
                 */}
                <Route path="/talk" element={<RedirectToTalkDetail />} />

                <Route
                  path="/admin/member-list"
                  element={
                    <BodyClassName className="member-page">
                      <Member />
                    </BodyClassName>
                  }
                />
                {/* <Route
                path="/video-setting"
                element={
                  <BodyClassName className="">
                    <VideoSetting />
                  </BodyClassName>
                }
              /> */}
                <Route
                  path="/chat-list"
                  element={
                    <BodyClassName className="chat_page">
                      <ChatList />
                    </BodyClassName>
                  }
                />
                <Route
                  path="/chat-detail"
                  element={
                    <BodyClassName className="chat_page">
                      <ChatDetail />
                    </BodyClassName>
                  }
                />
                <Route
                  path="/ranking"
                  element={
                    <BodyClassName className="ranking_page">
                      <Ranking />
                    </BodyClassName>
                  }
                />
                <Route
                  path="/cube-summary"
                  element={
                    <BodyClassName className="cube_summary_page">
                      <CubeSummary />
                    </BodyClassName>
                  }
                />
                <Route
                  path="/notification"
                  element={
                    <BodyClassName className="notification_page">
                      <Notification />
                    </BodyClassName>
                  }
                />
                <Route
                  path="/recommend-email"
                  element={
                    <BodyClassName className="">
                      <RecommendEmail />
                    </BodyClassName>
                  }
                />
                <Route
                  path="/recommend-email-from-cube"
                  element={
                    <BodyClassName className="">
                      <RecommendEmailFromCube />
                    </BodyClassName>
                  }
                />
                <Route
                  path="/developer/registration"
                  element={
                    <BodyClassName className="">
                      <Registration />
                    </BodyClassName>
                  }
                />
                <Route
                  path="/developer/ranking"
                  element={
                    <BodyClassName className="">
                      <Ranking developerMode />
                    </BodyClassName>
                  }
                />
                <Route
                  path="/developer/cube-summary"
                  element={
                    <BodyClassName className="">
                      <CubeSummary developerMode />
                    </BodyClassName>
                  }
                />
                {/* 権限エラーページ */}
                <Route
                  path="/not-found"
                  element={
                    <BodyClassName className="">
                      <NotFoundPage />
                    </BodyClassName>
                  }
                />
              </Routes>
            </ModalProvider>
          </RecoilRoot>
        </BrowserRouter>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </HelmetProvider>
    </React.StrictMode>,
  )
})
