import React, { FC, useState } from 'react'
import { toast } from 'react-toastify'
import apiErrorHandler from '../../api/apiErrorHandler'
import {
  CubeControllerService,
  CubeQuery,
  MemberCubeControllerService,
  MemberCubeForm,
  MemberCubesForm,
} from '../../services'
import Loading from 'react-loading'
import { Button } from '../../components/button'

interface Props {
  memberId: number
  closeModal: () => void
}
export const CubeExtractModal: FC<Props> = ({ memberId, closeModal }) => {
  const [cubeExtractStatus, setCubeExtractStatus] = useState(
    '' as 'extracting' | 'extracted' | 'creating' | '',
  )
  const [fileName, setFileName] = useState('')
  const [extractedCubes, setExtractedCubes] = useState([] as CubeQuery[])
  const [checkedCubes, setCheckedCubes] = useState([] as CubeQuery[])

  // キューブ抽出
  const createCubeExtractModal = () => {
    // ファイル選択ボタンがクリックされたときの処理
    const fileSelect = () => {
      const inputElement = document.getElementById('file') as HTMLInputElement
      inputElement.value = ''
      setFileName('')
      inputElement.click()
    }

    // ファイルが選択されたときの処理
    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0]
      setFileName(file?.name || '')
    }

    const isFileNameEmpty = fileName === ''
    // キューブ抽出処理
    const extract = () => {
      // ファイルが選択されていない場合は何もしない
      if (isFileNameEmpty) {
        return
      }
      setCubeExtractStatus('extracting')

      const inputElement = document.getElementById('file') as HTMLInputElement
      CubeControllerService.extractCubes({
        // @ts-ignore
        file: inputElement.files?.[0],
      })
        .then(res => {
          if (!res || !res.length) {
            toast.warning('有効なデータが見つかりませんでした')
            setCubeExtractStatus('')
          } else {
            setExtractedCubes(res)
            setCheckedCubes(res)
            setCubeExtractStatus('extracted')
          }
        })
        .catch(err => {
          toast.error('抽出に失敗しました')
          setCubeExtractStatus('')
          apiErrorHandler(err)
        })
    }

    return (
      <div
        className="extract-cube__content modal__content"
        role="dialog"
        aria-modal="true"
        aria-labelledby="extract-cube__title"
      >
        <div className="modal-title_wrapper">
          <h1 className="modal-edit__title" id="extract-cube__title">
            キューブを自動抽出
          </h1>

          <Button className="btn_modal_close" onClick={closeModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              aria-label="Close modal"
            >
              <rect width="24" height="24" rx="12" fill="#EDF0F7" />
              <path
                d="M13.1977 11.9992L16.5496 8.6473C16.7087 8.4885 16.7982 8.27301 16.7984 8.04824C16.7986 7.82346 16.7095 7.60781 16.5507 7.44873C16.3919 7.28965 16.1764 7.20017 15.9516 7.19997C15.7268 7.19977 15.5112 7.28887 15.3521 7.44767L12.0002 10.7996L8.64828 7.44767C8.4892 7.28859 8.27344 7.19922 8.04846 7.19922C7.82349 7.19922 7.60773 7.28859 7.44865 7.44767C7.28957 7.60675 7.2002 7.82251 7.2002 8.04749C7.2002 8.27246 7.28957 8.48822 7.44865 8.6473L10.8006 11.9992L7.44865 15.3511C7.28957 15.5102 7.2002 15.726 7.2002 15.951C7.2002 16.1759 7.28957 16.3917 7.44865 16.5508C7.60773 16.7098 7.82349 16.7992 8.04846 16.7992C8.27344 16.7992 8.4892 16.7098 8.64828 16.5508L12.0002 13.1989L15.3521 16.5508C15.5112 16.7098 15.727 16.7992 15.9519 16.7992C16.1769 16.7992 16.3927 16.7098 16.5517 16.5508C16.7108 16.3917 16.8002 16.1759 16.8002 15.951C16.8002 15.726 16.7108 15.5102 16.5517 15.3511L13.1977 11.9992Z"
                fill="#8A8F9F"
              />
            </svg>
          </Button>
        </div>

        <p className="extract-cube__text">
          ご自身のことがわかる書類をアップロードすると、自動でキューブを抽出します。
          <br />
          書類の一例：
          <br />
          ・職務経歴書
          <br />
          ・ポートフォリオ
          <br />
          ・関わったプロジェクトの提案書
        </p>
        <div className="extract-cube__form">
          <div className="form__item">
            <div className="form__label">
              <p className="extract-cube__label-name">ファイルから抽出</p>
              <Button className="btn_secondary-s" onClick={fileSelect}>
                ファイルをアップロード
              </Button>
              <input
                type="file"
                id="file"
                className="form__input--hidden"
                accept="text/plain,.pdf,.docx,.xlsx,.pptx"
                onChange={handleFileSelect}
                style={{ display: 'none' }}
              />
              <span className="extract-cube__file-name">{fileName}</span>
              <p className="extract-cube__form-notion">
                128MBまでのpdfまたはdocsファイルをアップロード可能
                <br />※ アップロードされたファイルはparks上には保存されません。
              </p>
            </div>
          </div>

          <div className="modal-edit-btn__wrapper extract-cube__btns">
            <Button className="btn_secondary-l" onClick={closeModal}>
              キャンセル
            </Button>

            <Button
              className={`btn ${isFileNameEmpty ? 'disabled' : ''}`}
              onClick={extract}
              disabled={isFileNameEmpty}
            >
              キューブを抽出する
            </Button>
          </div>
        </div>
      </div>
    )
  }

  // キューブ抽出中
  const createCubeLoadingModal = (cancelButtonDisabled: boolean) => {
    return (
      <div
        className="extracting-cube__content modal__content"
        role="dialog"
        aria-modal="true"
        aria-labelledby="extracting-cube__title"
      >
        <div className="modal-title_wrapper">
          <h1 className="modal-edit__title" id="extracting-cube__title">
            キューブを自動抽出
          </h1>

          <Button className="btn_modal_close" onClick={closeModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              aria-label="Close modal"
            >
              <rect width="24" height="24" rx="12" fill="#EDF0F7" />
              <path
                d="M13.1977 11.9992L16.5496 8.6473C16.7087 8.4885 16.7982 8.27301 16.7984 8.04824C16.7986 7.82346 16.7095 7.60781 16.5507 7.44873C16.3919 7.28965 16.1764 7.20017 15.9516 7.19997C15.7268 7.19977 15.5112 7.28887 15.3521 7.44767L12.0002 10.7996L8.64828 7.44767C8.4892 7.28859 8.27344 7.19922 8.04846 7.19922C7.82349 7.19922 7.60773 7.28859 7.44865 7.44767C7.28957 7.60675 7.2002 7.82251 7.2002 8.04749C7.2002 8.27246 7.28957 8.48822 7.44865 8.6473L10.8006 11.9992L7.44865 15.3511C7.28957 15.5102 7.2002 15.726 7.2002 15.951C7.2002 16.1759 7.28957 16.3917 7.44865 16.5508C7.60773 16.7098 7.82349 16.7992 8.04846 16.7992C8.27344 16.7992 8.4892 16.7098 8.64828 16.5508L12.0002 13.1989L15.3521 16.5508C15.5112 16.7098 15.727 16.7992 15.9519 16.7992C16.1769 16.7992 16.3927 16.7098 16.5517 16.5508C16.7108 16.3917 16.8002 16.1759 16.8002 15.951C16.8002 15.726 16.7108 15.5102 16.5517 15.3511L13.1977 11.9992Z"
                fill="#8A8F9F"
              />
            </svg>
          </Button>
        </div>

        {/*
          <div>
            <p className="extracting-cube__text">キューブを抽出しています...</p>
            <div className="extracting-cube__circle">
              <svg>
                <circle className="base" cx="62" cy="62" r="58"></circle>
                <circle className="line" cx="62" cy="62" r="60"></circle>
              </svg>
              <div className="extracting-cube__percencubee-inner">
                <h3 className="extracting-cube__percencubee">
                  <span>75</span>%
                </h3>
              </div>
            </div>
          </div>
          */}
        <Loading className="loading" type="spin" color="#007559" />
        {!cancelButtonDisabled && (
          <div className="extracting-cube__btn">
            <Button className="btn_secondary-l" onClick={closeModal}>
              キャンセル
            </Button>
          </div>
        )}
      </div>
    )
  }

  // キューブ抽出完了
  const createCubeExtractedModal = () => {
    // キューブをチェックしたときの処理
    const checkCube = (cube: CubeQuery) => {
      if (checkedCubes.some(t => t.cubeName === cube.cubeName)) {
        const newCheckedCubes = checkedCubes.filter(
          t => t.cubeName !== cube.cubeName,
        )
        setCheckedCubes(newCheckedCubes)
      } else {
        setCheckedCubes([...checkedCubes, cube])
      }
    }

    // キューブ登録
    const createCubes = () => {
      if (checkedCubes.length === 0) return
      setCubeExtractStatus('creating')
      const memberCubeForms = checkedCubes.map(cube => {
        return {
          memberId: memberId,
          cubeName: cube.cubeName,
        } as MemberCubeForm
      })
      MemberCubeControllerService.createMemberCubes({
        memberCubes: memberCubeForms,
      } as MemberCubesForm)
        .then(() => {
          closeModal()
          toast.success('キューブを登録しました')
        })
        .catch(err => {
          toast.error('登録に失敗しました')
          apiErrorHandler(err)
        })
    }

    return (
      <div
        className="extract-result__content modal__content"
        role="dialog"
        aria-modal="true"
        aria-labelledby="extract-result__title"
      >
        <div className="modal-title_wrapper">
          <h1 className="modal-edit__title" id="extract-result__title">
            キューブを自動抽出
          </h1>

          <Button className="btn_modal_close" onClick={closeModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              aria-label="Close modal"
            >
              <rect width="24" height="24" rx="12" fill="#EDF0F7" />
              <path
                d="M13.1977 11.9992L16.5496 8.6473C16.7087 8.4885 16.7982 8.27301 16.7984 8.04824C16.7986 7.82346 16.7095 7.60781 16.5507 7.44873C16.3919 7.28965 16.1764 7.20017 15.9516 7.19997C15.7268 7.19977 15.5112 7.28887 15.3521 7.44767L12.0002 10.7996L8.64828 7.44767C8.4892 7.28859 8.27344 7.19922 8.04846 7.19922C7.82349 7.19922 7.60773 7.28859 7.44865 7.44767C7.28957 7.60675 7.2002 7.82251 7.2002 8.04749C7.2002 8.27246 7.28957 8.48822 7.44865 8.6473L10.8006 11.9992L7.44865 15.3511C7.28957 15.5102 7.2002 15.726 7.2002 15.951C7.2002 16.1759 7.28957 16.3917 7.44865 16.5508C7.60773 16.7098 7.82349 16.7992 8.04846 16.7992C8.27344 16.7992 8.4892 16.7098 8.64828 16.5508L12.0002 13.1989L15.3521 16.5508C15.5112 16.7098 15.727 16.7992 15.9519 16.7992C16.1769 16.7992 16.3927 16.7098 16.5517 16.5508C16.7108 16.3917 16.8002 16.1759 16.8002 15.951C16.8002 15.726 16.7108 15.5102 16.5517 15.3511L13.1977 11.9992Z"
                fill="#8A8F9F"
              />
            </svg>
          </Button>
        </div>

        <p className="extract-result__text">
          抽出結果からキューブを選択してください。選択されたキューブはプロフィールに追加されます
        </p>
        <div className="extract-result__checkbox-list">
          {extractedCubes.map((cube, index) => (
            <p className="form__check" key={index}>
              <input
                type="checkbox"
                id={`management-${index}`}
                name={`management-${index}`}
                checked={checkedCubes.some(
                  target => target.cubeName === cube.cubeName,
                )}
                onChange={() => checkCube(cube)}
              />
              <label htmlFor={`management-${index}`}>{cube.cubeName}</label>
            </p>
          ))}
        </div>

        <div className="modal-edit-btn__wrapper extract-cube__btns">
          <Button className="btn_secondary-l" onClick={closeModal}>
            キャンセル
          </Button>
          <Button className="btn" onClick={createCubes}>
            キューブを追加する
          </Button>
        </div>
      </div>
    )
  }

  switch (cubeExtractStatus) {
    case 'extracting':
      return createCubeLoadingModal(false)
    case 'extracted':
      return createCubeExtractedModal()
    case 'creating':
      return createCubeLoadingModal(true)
    default:
      return createCubeExtractModal()
  }
}
