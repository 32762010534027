import { format } from 'date-fns'
import { ChatRoomAttendeeQuery } from '../services'
import env from '../config/env'

export const getChatMessageAt = (messageAt?: string) => {
  if (!messageAt) {
    return ''
  }
  // 前日の日付であれば、日付を表示
  if (new Date(messageAt).getDate() !== new Date().getDate()) {
    return format(new Date(messageAt), 'MM/dd')
  }
  // 今日の日付であれば、時間を表示
  return format(new Date(messageAt), 'HH:mm')
}

export const getAttendeesWithoutLoggedInMember = (
  attendees: ChatRoomAttendeeQuery[],
  loggedInMember: { memberId: number; workspaceId: string },
) => {
  return attendees.filter(e => e.memberId !== loggedInMember.memberId)
}

export const getSingleAttendeeMemberProfileImageUrl = (
  attendees: ChatRoomAttendeeQuery[],
  loggedInMember: { memberId: number; workspaceId: string },
) => {
  const attendee = getAttendeesWithoutLoggedInMember(
    attendees,
    loggedInMember,
  )[0]
  return attendee?.profileImageUrl
    ? attendee.profileImageUrl
    : '/icons/avatar-sample.png'
}

// チャット参加者の名前を自分以外で表示する 20文字以上は...にする
export const getChatRoomName = (
  attendees: ChatRoomAttendeeQuery[],
  loggedInMember: { memberId: number; workspaceId: string },
) => {
  const names = attendees
    .filter(e => e.memberId !== loggedInMember.memberId)
    .map(e => e.memberName)
    .join(', ')
  const NAMES_LENGTH_LIMIT = 20

  if (names.length > NAMES_LENGTH_LIMIT) {
    return `${names.slice(0, NAMES_LENGTH_LIMIT)}...(${attendees.length})`
  }
  // 3人以上の場合は names(3)のように人数表現する
  if (attendees.length >= 3) {
    return `${names}(${attendees.length})`
  }
  if (env.ENV === 'mock') {
    return 'string'
  }
  return names
}
