import React, { FC, useState } from 'react'
import { CareerQuery, MemberControllerService } from '../../services'
import apiErrorHandler from '../../api/apiErrorHandler'
import { PeriodInput } from '../../components/periodInput'
import {
  changeCareerValue,
  clearValidationError,
  validateCareer,
} from '../../lib/career'
import { TextInput } from '../../components/textInput'
import { Button } from '../../components/button'

interface Props {
  careers: CareerQuery[]
  target?: CareerQuery
  closeModal: () => void
}
export const CareerEditModal: FC<Props> = ({ careers, target, closeModal }) => {
  const [career, setCareer] = useState(target)
  const [companyNameErrors, setCompanyNameErrors] = useState<string[]>([])
  const [occupationNameErrors, setOccupationNameErrors] = useState<string[]>([])
  const [enrollmentPeriodErrors, setEnrollmentPeriodErrors] = useState<
    string[]
  >([])

  const addCareer = () => {
    if (
      !career ||
      !validateCareer(
        career,
        0,
        setCompanyNameErrors,
        setOccupationNameErrors,
        setEnrollmentPeriodErrors,
      )
    ) {
      return
    }

    const newCareer = { ...career } as CareerQuery
    MemberControllerService.updateMember({
      careers: [...careers, newCareer],
    })
      .then(closeModal)
      .catch(apiErrorHandler)
  }

  const updateCareer = () => {
    if (
      !career ||
      !validateCareer(
        career,
        0,
        setCompanyNameErrors,
        setOccupationNameErrors,
        setEnrollmentPeriodErrors,
      )
    ) {
      return
    }

    // careersからtargetを置き換える
    const newCareer = { ...career } as CareerQuery
    const newCareers = [...careers]
    newCareers[newCareers.indexOf(target as CareerQuery)] = newCareer
    MemberControllerService.updateMember({
      careers: newCareers,
    })
      .then(closeModal)
      .catch(apiErrorHandler)
  }

  const deleteCareer = () => {
    // 確認
    if (!window.confirm('この経歴を削除しますか？')) return
    // careersからtargetを削除
    const newCareers = [...careers]
    newCareers.splice(newCareers.indexOf(target as CareerQuery), 1)
    MemberControllerService.updateMember({
      careers: newCareers,
    })
      .then(closeModal)
      .catch(apiErrorHandler)
  }

  return (
    <div
      className="career-edit__content"
      role="dialog"
      aria-modal="true"
      aria-labelledby="career-edit__title"
    >
      <div className="modal-title_wrapper">
        <h1 className="modal-edit__title" id="career-edit__title">
          経歴を{target ? '編集' : '追加'}
        </h1>

        <Button className="btn_modal_close" onClick={closeModal}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            aria-label="Close modal"
          >
            <rect width="24" height="24" rx="12" fill="#EDF0F7" />
            <path
              d="M13.1977 11.9992L16.5496 8.6473C16.7087 8.4885 16.7982 8.27301 16.7984 8.04824C16.7986 7.82346 16.7095 7.60781 16.5507 7.44873C16.3919 7.28965 16.1764 7.20017 15.9516 7.19997C15.7268 7.19977 15.5112 7.28887 15.3521 7.44767L12.0002 10.7996L8.64828 7.44767C8.4892 7.28859 8.27344 7.19922 8.04846 7.19922C7.82349 7.19922 7.60773 7.28859 7.44865 7.44767C7.28957 7.60675 7.2002 7.82251 7.2002 8.04749C7.2002 8.27246 7.28957 8.48822 7.44865 8.6473L10.8006 11.9992L7.44865 15.3511C7.28957 15.5102 7.2002 15.726 7.2002 15.951C7.2002 16.1759 7.28957 16.3917 7.44865 16.5508C7.60773 16.7098 7.82349 16.7992 8.04846 16.7992C8.27344 16.7992 8.4892 16.7098 8.64828 16.5508L12.0002 13.1989L15.3521 16.5508C15.5112 16.7098 15.727 16.7992 15.9519 16.7992C16.1769 16.7992 16.3927 16.7098 16.5517 16.5508C16.7108 16.3917 16.8002 16.1759 16.8002 15.951C16.8002 15.726 16.7108 15.5102 16.5517 15.3511L13.1977 11.9992Z"
              fill="#8A8F9F"
            />
          </svg>
        </Button>
      </div>

      <div className="career-edit__form">
        <div className="form__item">
          <TextInput
            label="会社名（必須）"
            placeholder="株式会社Rond"
            value={career?.companyName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCareer(
                changeCareerValue(career, 'companyName', event.target.value),
              )
              clearValidationError(0, setCompanyNameErrors)
            }}
            maxLength={64}
            error={companyNameErrors[0]}
          />
        </div>
        <div className="form__item">
          <TextInput
            label="職種（必須）"
            placeholder="UI/UXデザイナー"
            value={career?.occupationName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCareer(
                changeCareerValue(career, 'occupationName', event.target.value),
              )
              clearValidationError(0, setOccupationNameErrors)
            }}
            maxLength={64}
            error={occupationNameErrors[0]}
          />
        </div>
        <div className="form__item">
          <div className="form__label">
            在籍期間
            <p className="form__check">
              <input
                type="checkbox"
                id="current-job"
                name="current-job"
                checked={career?.currentFlg}
                onChange={event => {
                  setCareer(
                    changeCareerValue(
                      career,
                      'currentFlg',
                      event.target.checked.toString(),
                    ),
                  )
                }}
              />
              <label htmlFor="current-job">現職</label>
            </p>
          </div>

          <PeriodInput
            label=""
            periodStart={career?.enrollmentPeriodStart}
            periodEnd={career?.currentFlg ? '' : career?.enrollmentPeriodEnd}
            setPeriodStart={(value: string | undefined) => {
              setCareer(
                changeCareerValue(career, 'enrollmentPeriodStart', value),
              )
              clearValidationError(0, setEnrollmentPeriodErrors)
            }}
            setPeriodEnd={(value: string | undefined) => {
              setCareer(changeCareerValue(career, 'enrollmentPeriodEnd', value))
              clearValidationError(0, setEnrollmentPeriodErrors)
            }}
            periodEndDisabled={career?.currentFlg}
            error={enrollmentPeriodErrors[0]}
          />
        </div>

        <p
          className={`form__check form__check-profile-headline ${
            !career?.currentFlg && 'form__check-profile-headline-disabled'
          }`}
        >
          <input
            type="checkbox"
            id="profile-headline-display"
            className="profile-headline-display"
            name="profile-headline-display"
            checked={career?.currentFlg && career?.headlineFlg}
            onChange={event => {
              setCareer(
                changeCareerValue(
                  career,
                  'headlineFlg',
                  event.target.checked.toString(),
                ),
              )
            }}
            disabled={!career?.currentFlg}
          />
          <label htmlFor="profile-headline-display">
            この経歴をプロフィールのヘッドラインに表示
          </label>
        </p>

        <div className="modal-edit-btn__wrapper career-edit-btn__wrapper">
          <div className="modal-edit-btn__wrapper-left">
            {target && (
              <Button className="btn_career-trash" onClick={deleteCareer}>
                この経歴を削除
              </Button>
            )}
          </div>

          <div className="modal-edit-btn__wrapper-right">
            <Button
              className="btn_secondary-l btn_modal-edit_cancel"
              onClick={closeModal}
            >
              キャンセル
            </Button>
            <Button
              className="btn btn_modal-edit_save"
              onClick={target ? updateCareer : addCareer}
            >
              保存
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
