import { KeyboardEvent } from 'react'
import { FC, useEffect, useRef, useState } from 'react'
import { Head } from '../../layouts/head'
import { Header } from '../../layouts/header'
import { SideNavi } from '../../layouts/sideNavi'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  ChatMessageReadControllerService,
  ChatRoomAttendeeQuery,
  ChatRoomControllerService,
} from '../../services'
import { useRecoilValue } from 'recoil'
import { loggedInMemberState } from '../../lib/atoms'
import { useChatRoom } from '../../hooks/useChatRoom'
import { HeaderType } from '../../enums/HeaderType'
import apiErrorHandler from '../../api/apiErrorHandler'
import { toast } from 'react-toastify'
import { getChatRoomName } from '../../lib/chatUtils'
import ChatMessageItem from '../../components/chatDetail/ChatMessageItem'
import { ChatInput } from '../../components/chatDetail/ChatInput'

export const ChatDetail: FC = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const chatRoomId = searchParams.get('chatRoomId')
  const memberAddedStatus = searchParams.get('member')
  const loggedInMember = useRecoilValue(loggedInMemberState)
  const scrollRef = useRef<any>(null)

  const [chatRoomAttendees, setChatRoomAttendees] = useState<
    ChatRoomAttendeeQuery[]
  >([])

  const scrollCurrentMessage = () => {
    setTimeout(() => {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, 50)
  }

  // useChatRoomにチャットルームに関するWebSocket処理が入っているので、それらを使う
  const {
    setChatMessageForm,
    connectChatRoom,
    sendChatMessage,
    setChatMessages,
    chatMessageForm,
    chatMessages,
  } = useChatRoom()

  useEffect(() => {
    ChatRoomControllerService.getChatMessages(chatRoomId as string)
      .then(res => {
        setChatMessages(res)
        scrollCurrentMessage()
      })
      .catch(apiErrorHandler)

    ChatRoomControllerService.getChatRoomAttendees(chatRoomId as string)
      .then(res => {
        setChatRoomAttendees(res)
      })
      .catch(apiErrorHandler)

    if (chatRoomId) {
      // クエリパラメータのchatRoomIdを使ってチャットルームに接続（websocket接続）
      connectChatRoom(chatRoomId, scrollCurrentMessage)
      // chatRoomIdに紐づく未読のチャットメッセージを全て既読にする
      ChatMessageReadControllerService.readUnreadChatMessages(chatRoomId)
    }
  }, [searchParams])

  useEffect(() => {
    // TODO: なぜか2回実行されてしまうので、直す
    if (memberAddedStatus === 'added') {
      toast.success('メンバーを追加しました')
      searchParams.delete('member')
      navigate(`${location.pathname}?${searchParams.toString()}`)
    }
  }, [memberAddedStatus])

  // テキストエリアは8行まで表示
  const calculateTextAreaRows = () => {
    const lines = chatMessageForm.split('\n').length
    return Math.min(lines, 8)
  }

  // ショートカットでメッセージ送信
  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
      e.preventDefault()
      sendChatMessage(chatMessageForm)
    }
  }

  return (
    <>
      <Head />
      <Header
        headerType={HeaderType.SIMPLE_WITHOUT_SEARCH}
        headerTitle={getChatRoomName(chatRoomAttendees, loggedInMember)}
        isChatRoomTitle={true}
      />
      <SideNavi />
      <main className="main-sidenav">
        <div className="chat__wrapper">
          <ul className="chat__list">
            {chatMessages.map((message, index) => (
              <ChatMessageItem
                key={index}
                message={message}
                isFromMe={message.memberId === loggedInMember?.memberId}
                scrollRef={
                  index === chatMessages.length - 1 ? scrollRef : undefined
                }
              />
            ))}
          </ul>
          <ChatInput
            chatMessageForm={chatMessageForm}
            setChatMessageForm={setChatMessageForm}
            handleKeyDown={handleKeyDown}
            calculateTextAreaRows={calculateTextAreaRows}
            sendChatMessage={sendChatMessage}
          />
        </div>
      </main>
    </>
  )
}
