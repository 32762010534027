import React from 'react'
import { Link } from 'react-router-dom'
import {
  MemberCubeQuery,
  SimpleMemberQuery,
  TimelineCardQuery,
} from '../../services'
import TextInputWithButton from '../TextInputWithButton'

interface IndividualUpdateCubeCardProps {
  timelineCard: TimelineCardQuery
  index: number
  member: SimpleMemberQuery
  suffix: string
  onSendMessage: (message: string) => void
  children: React.ReactNode
}

const IndividualUpdateCubeCard: React.FC<IndividualUpdateCubeCardProps> = ({
  timelineCard,
  member,
  index,
  suffix = '',
  onSendMessage,
  children,
}) => {
  // キューブをカテゴリーごとにグルーピング
  const cubeMap = new Map<string, MemberCubeQuery[]>()
  timelineCard.updateCubeCard?.linkMemberCubes?.forEach(cube => {
    const cubeCategoryName = cube.cubeCategory.cubeCategoryName
    if (cubeMap.has(cubeCategoryName)) {
      cubeMap.get(cubeCategoryName)?.push(cube)
    } else {
      cubeMap.set(cubeCategoryName, [cube])
    }
  })

  // メッセージをグルーピング
  const messageMap = new Map<string | undefined, number[]>()
  timelineCard.updateCubeCard?.messages.forEach(messageQuery => {
    const message = messageQuery.message
    if (messageMap.has(message)) {
      const memberCubeIds = messageMap.get(message)
      if (memberCubeIds) {
        memberCubeIds.push(messageQuery.memberCubeId)
        messageMap.set(message, memberCubeIds)
      }
    } else {
      messageMap.set(message, [messageQuery.memberCubeId])
    }
  })

  return (
    <li
      className="home-timeline__card home-timeline__card-cube home-timeline__card-cube--simple"
      key={index}
    >
      <Link
        className="home-timeline__card-link"
        to={`/member-detail/${member.memberId}`}
      >
        <div className="home-timeline__card-upper">
          <div className="home-timeline__card-upper-avater">
            <img
              src={
                member.profileImageUrl
                  ? member.profileImageUrl
                  : '/icons/avatar-sample.png'
              }
              alt="プロフィール画像"
              width={32}
              height={32}
            />
          </div>
          <div className="home-timeline__card-upper-content">
            <h2 className="home-timeline__card-upper-title">
              <span>{member.memberName}</span>
              さんは{suffix}
            </h2>
            <span className="home-timeline__card-posttime">
              {timelineCard.createdAt}
            </span>
          </div>
        </div>

        <div className="home-timeline__card-upper-content">{children}</div>
      </Link>
      <TextInputWithButton
        onSend={onSendMessage}
        placeholder={`${member.memberName}さんへのコメント`}
      />
    </li>
  )
}

export default IndividualUpdateCubeCard
