import React from 'react'
import { Button } from '../button'

interface SingleWordInputProps {
  memberName: string
  singleWordPlaceholder?: string
  singleWord?: string
  updateSingleWord: () => void
  changeSingleWord: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
}

const SingleWordInput: React.FC<SingleWordInputProps> = ({
  memberName,
  singleWordPlaceholder,
  singleWord,
  updateSingleWord,
  changeSingleWord,
}) => {
  return (
    <li className="home-timeline__card home-timeline__card-input">
      <div className="home-timleine__card-inner">
        <h2 className="home-timeline__card-input-title">
          {memberName}さん、ひとことを更新しませんか？
        </h2>
        <div className="home-timeline__card-input-wrapper">
          <textarea
            name="comment"
            cols={30}
            rows={10}
            maxLength={32}
            placeholder={
              singleWordPlaceholder
                ? singleWordPlaceholder
                : singleWord
                ? singleWord
                : 'ひとことを入力してください'
            }
            value={singleWord}
            onChange={changeSingleWord}
          ></textarea>
          <Button
            className="home-timeline__card-input-btn"
            onClick={updateSingleWord}
            disabled={!singleWord}
          >
            更新
          </Button>
        </div>
        <p className="home-timeline__card-input-text">
          ひとことを更新してメンバーと繋がろう！（32文字）
          <br />
          更新するとフィードに投稿されます。
        </p>
      </div>
    </li>
  )
}

export default SingleWordInput
