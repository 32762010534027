import React, { FC, useEffect, useState } from 'react'
import { Head } from '../../layouts/head'
import { SideNavi } from '../../layouts/sideNavi'
import apiErrorHandler from '../../api/apiErrorHandler'
import {
  AdminControllerService,
  AdminMember,
  CommonControllerService,
  MemberControllerService,
  MemberQuery,
  MemberRoleMap,
  MemberRoleQuery,
  UpdateMemberForm,
} from '../../services'
import { MemberSaveModal } from '../../modals/memberSaveModal'
import { useModal } from '../../contexts/modalContext'
import { AdminProfileEditModal } from '../../modals/adminProfileEditModal'
import { MemberInviteForMailModal } from '../../modals/memberInviteForMailModal'
import { Button } from '../../components/button'

export const Member: FC = () => {
  const { openModal } = useModal()

  const [member, setMember] = useState({} as MemberQuery)

  const [memberRoleMaps, setMemberRoleMaps] = useState<MemberRoleMap[]>([])
  const [adminMembers, setAdminMembers] = useState<AdminMember[]>([])
  const [selectedMemberRoles, setSelectedMemberRoles] = useState<
    MemberRoleQuery.memberRole[]
  >([])

  const [isActive, setIsActive] = useState(true)
  const [isDeleted, setIsDeleted] = useState(false)
  const [isNotLogin, setIsNotLogin] = useState(false)

  useEffect(() => {
    MemberControllerService.getMember(0)
      .then(res => {
        setMember(res)
      })
      .catch(err => {
        apiErrorHandler(err)
      })

    setSelectedMemberRoles(Object.values(AdminMember.memberRole))
    CommonControllerService.getMemberRoles()
      .then(res => {
        setMemberRoleMaps(res)
      })
      .catch(err => {
        apiErrorHandler(err)
      })
    AdminControllerService.getAdminMembers()
      .then(res => {
        setAdminMembers(res)
      })
      .catch(err => {
        apiErrorHandler(err)
      })
  }, [openModal])

  const getFilteredAdminMembers = () => {
    return (
      adminMembers
        // キーワードで絞り込み
        ?.filter(
          adminMember =>
            adminMember.memberName.includes(keyword) ||
            adminMember.email.includes(keyword),
        )
        // 権限で絞り込み
        .filter(adminMember =>
          selectedMemberRoles.includes(adminMember.memberRole),
        )
        // ステータスで絞り込み
        .filter(
          adminMember =>
            (isActive && !adminMember.deleteFlg && adminMember.loginFlg) ||
            (isDeleted && adminMember.deleteFlg && adminMember.loginFlg) ||
            (isNotLogin && !adminMember.loginFlg),
        )
    )
  }

  const [keyword, setKeyword] = useState('')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value)
  }

  const updateMemberStatus = (adminMember: AdminMember) => {
    window.confirm(
      adminMember.deleteFlg
        ? 'メンバーを有効にしますか？'
        : 'メンバーを休止にしますか？',
    ) &&
      AdminControllerService.updateMemberFromAdmin(adminMember.memberId, {
        deleteFlg: !adminMember.deleteFlg,
      } as UpdateMemberForm)
        .then(() => {
          // 再取得
          AdminControllerService.getAdminMembers()
            .then(res => {
              setAdminMembers(res)
            })
            .catch(err => {
              apiErrorHandler(err)
            })
        })
        .catch(err => {
          apiErrorHandler(err)
        })
  }
  return (
    <>
      <Head />
      {/* 画面遷移なしで検索を使うために組み込み */}
      <header className="header_login">
        <div className="header_login__inner">
          <div className="header_login__input-box">
            <input
              type="text"
              placeholder="キーワードで絞り込み"
              value={keyword}
              onChange={handleChange}
            />
          </div>
          <div className="header_login__right-box">
            {/* TODO 通知画面がないので非表示 */}
            {/*
              <button className="header_login__btn_nortification">
                <img
                  src="/images/icon_timeline.svg"
                  alt="ベルマークにチェックあり"
                />
              </button>
              */}
            <Button
              className="header_login__btn_avatar"
              onClick={() => {
                window.location.href = '/profile'
              }}
            >
              <img
                src={
                  member.profileImageUrl
                    ? member.profileImageUrl
                    : '/icons/avatar-sample.png'
                }
                alt="プロフィール画像"
                className="header_login__avatar_img"
              />
            </Button>
          </div>
        </div>
      </header>
      <SideNavi />
      <main className="main-sidenav">
        <div className="member-page__inner">
          <div className="search_input-page__conditions">
            <div className="search_input-page__lead-wrapper">
              <p className="search_input-page__lead">権限で絞り込み</p>
              <ul className="search_input-page_chips__list">
                {Object.values(AdminMember.memberRole).map(
                  (memberRole, index) => {
                    const isSelected = selectedMemberRoles.includes(memberRole)
                    const label =
                      memberRoleMaps.find(
                        memberRoleMap => memberRoleMap.name === memberRole,
                      )?.label || memberRole
                    const handleClick = () => {
                      const updatedRoles = isSelected
                        ? selectedMemberRoles.filter(
                            selectedMemberRole =>
                              selectedMemberRole !== memberRole,
                          )
                        : [...selectedMemberRoles, memberRole]
                      setSelectedMemberRoles(updatedRoles)
                    }
                    return (
                      <li
                        className="search_input-page_chips-list__item"
                        key={index}
                      >
                        <Button
                          className={`chips ${
                            isSelected ? 'chips-selected' : ''
                          }`}
                          onClick={handleClick}
                        >
                          {label}
                        </Button>
                      </li>
                    )
                  },
                )}
              </ul>
            </div>
            <div className="search_input-page__lead-wrapper">
              <p className="search_input-page__lead">ステータスで絞り込み</p>
              <ul className="search_input-page_chips__list">
                <li className="search_input-page_chips-list__item">
                  <Button
                    className={`chips ${isActive ? 'chips-selected' : ''}`}
                    onClick={() => setIsActive(!isActive)}
                  >
                    有効
                  </Button>
                </li>
                <li className="search_input-page_chips-list__item">
                  <Button
                    className={`chips ${isDeleted ? 'chips-selected' : ''}`}
                    onClick={() => setIsDeleted(!isDeleted)}
                  >
                    休止
                  </Button>
                </li>
                <li className="search_input-page_chips-list__item">
                  <Button
                    className={`chips ${isNotLogin ? 'chips-selected' : ''}`}
                    onClick={() => setIsNotLogin(!isNotLogin)}
                  >
                    未ログイン
                  </Button>
                </li>
              </ul>
            </div>
          </div>
          <div className="member-page_top-block">
            <div className="member-page_search-wrapper">
              <div className="member-page_search-title">メンバー</div>
              <div className="member-page_search-results">
                {getFilteredAdminMembers().length}人
              </div>
            </div>
            <div className="member-page_search-btn-wrapper">
              <Button
                className="btn btn__primary"
                onClick={() => {
                  openModal(MemberSaveModal)
                }}
              >
                メンバーを招待
              </Button>

              <Button
                className="btn btn_secondary-l"
                onClick={() => {
                  openModal(MemberInviteForMailModal)
                }}
              >
                招待URL発行(メールログイン用)
              </Button>
            </div>
          </div>
          <div className="search_input-page__block member-page__block">
            <ul className="search_input-page__list member-page__list">
              {getFilteredAdminMembers().map((adminMember, index) => (
                <li
                  className="search_input-page-list__item member-page-list__item"
                  key={index}
                >
                  <div
                    className="card_list-m"
                    onClick={event => {
                      event.stopPropagation()
                      event.preventDefault()
                      openModal(AdminProfileEditModal, {
                        adminMember: adminMember,
                        memberRoleMaps: memberRoleMaps,
                        disabled: adminMember.memberId === member.memberId,
                      })
                    }}
                  >
                    <div className="card_list-m__head">
                      <div className="card_list-m__profile-wrapper">
                        <div className="card_list-m_avatar">
                          <img
                            src={
                              adminMember.profileImageUrl
                                ? adminMember.profileImageUrl
                                : '/icons/avatar-sample.png'
                            }
                            alt="プロフィール画像"
                          />
                        </div>
                        <div className="card_list-m__profile">
                          <div className="card_list-m_name__wrapper">
                            <h3 className="card_list-m_name">
                              {adminMember.memberName}
                            </h3>
                            <p className="card_list-m_year_of_service">
                              {memberRoleMaps.find(
                                memberRoleMap =>
                                  memberRoleMap.name === adminMember.memberRole,
                              )?.label || adminMember.memberRole}
                            </p>
                          </div>
                          <p className="card_list-m__position">
                            {adminMember.email}
                          </p>
                        </div>
                      </div>
                      <div className="btn_card_list-m__wrapper">
                        {adminMember.deleteFlg ? (
                          <Button
                            className="btn-s"
                            onClick={event => {
                              event.stopPropagation()
                              event.preventDefault()
                              updateMemberStatus(adminMember)
                            }}
                          >
                            有効
                          </Button>
                        ) : (
                          <Button
                            className="btn_secondary-s"
                            onClick={event => {
                              event.stopPropagation()
                              event.preventDefault()
                              updateMemberStatus(adminMember)
                            }}
                          >
                            休止
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </main>
    </>
  )
}
