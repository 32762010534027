import React, { FC, useEffect, useState } from 'react'
import ReactCrop, { makeAspectCrop, Crop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { uploadImage } from '../../lib/file'
import { Button } from '../../components/button'

interface Props {
  closeModal: () => void
  image: string
  setImage: (image: string) => void
}
export const ImageUploadModal: FC<Props> = ({
  closeModal,
  image,
  setImage,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [crop, setCrop] = useState<Crop | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  useEffect(() => {
    if (selectedFile) {
      const initialCrop = makeAspectCrop(
        { unit: 'px', width: 100, height: 100 },
        1,
        100,
        100,
      )
      setCrop(initialCrop)
    }
  }, [selectedFile])

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (file) {
      // ファイルサイズ制限と形式の確認
      if (
        file.size <= 10 * 1024 * 1024 &&
        ['image/jpeg', 'image/png', 'image/gif'].includes(file.type)
      ) {
        setSelectedFile(file)
        setErrorMessage(null)

        const reader = new FileReader()

        reader.onload = e => {
          const img = new Image()
          img.onload = () => {
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')

            const aspectRatio = img.width / img.height
            let newWidth = 400
            let newHeight = 400 / aspectRatio

            if (aspectRatio < 1) {
              newWidth = 400 * aspectRatio
              newHeight = 400
            }

            canvas.width = newWidth
            canvas.height = newHeight

            ctx?.drawImage(img, 0, 0, newWidth, newHeight)

            canvas.toBlob(blob => {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              const resizedFile = new File([blob!], file.name, {
                type: file.type,
              })
              setSelectedFile(resizedFile)
            }, file.type)
          }

          img.src = e.target?.result as string
        }

        reader.readAsDataURL(file)
      } else {
        setErrorMessage(
          '無効なファイル形式またはファイルサイズが制限を超えています。',
        )
        setSelectedFile(null)
      }
    }
  }

  const handleSave = () => {
    if (!selectedFile) {
      return
    }

    const canvas = document.createElement('canvas')
    const image = new Image()
    image.src = URL.createObjectURL(selectedFile)

    image.onload = () => {
      const scaleX = image.naturalWidth / image.width
      const scaleY = image.naturalHeight / image.height

      const ctx = canvas.getContext('2d')
      if (ctx) {
        const cropX = crop?.x || 0
        const cropY = crop?.y || 0
        const cropWidth = crop?.width || image.width
        const cropHeight = crop?.height || image.height

        const aspectRatio = cropWidth / cropHeight
        if (aspectRatio > 1) {
          canvas.width = cropWidth
          canvas.height = cropWidth / aspectRatio
        } else {
          canvas.width = cropHeight * aspectRatio
          canvas.height = cropHeight
        }

        ctx.drawImage(
          image,
          cropX * scaleX,
          cropY * scaleY,
          cropWidth * scaleX,
          cropHeight * scaleY,
          0,
          0,
          canvas.width,
          canvas.height,
        )

        // 画像確認
        const dataUrl = canvas.toDataURL()

        uploadImage(dataUrl)
          .then(res => {
            setImage(res || '')
          })
          .finally(closeModal)
      }
    }
  }

  return (
    <div className="modal__content upload-image__content">
      <div className="modal-title_wrapper">
        <h1 className="modal-edit__title" id="upload-image__title">
          プロフィール画像の編集
        </h1>

        <Button className="btn_modal_close" onClick={closeModal}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            aria-label="Close modal"
          >
            <rect width="24" height="24" rx="12" fill="#EDF0F7" />
            <path
              d="M13.1977 11.9992L16.5496 8.6473C16.7087 8.4885 16.7982 8.27301 16.7984 8.04824C16.7986 7.82346 16.7095 7.60781 16.5507 7.44873C16.3919 7.28965 16.1764 7.20017 15.9516 7.19997C15.7268 7.19977 15.5112 7.28887 15.3521 7.44767L12.0002 10.7996L8.64828 7.44767C8.4892 7.28859 8.27344 7.19922 8.04846 7.19922C7.82349 7.19922 7.60773 7.28859 7.44865 7.44767C7.28957 7.60675 7.2002 7.82251 7.2002 8.04749C7.2002 8.27246 7.28957 8.48822 7.44865 8.6473L10.8006 11.9992L7.44865 15.3511C7.28957 15.5102 7.2002 15.726 7.2002 15.951C7.2002 16.1759 7.28957 16.3917 7.44865 16.5508C7.60773 16.7098 7.82349 16.7992 8.04846 16.7992C8.27344 16.7992 8.4892 16.7098 8.64828 16.5508L12.0002 13.1989L15.3521 16.5508C15.5112 16.7098 15.727 16.7992 15.9519 16.7992C16.1769 16.7992 16.3927 16.7098 16.5517 16.5508C16.7108 16.3917 16.8002 16.1759 16.8002 15.951C16.8002 15.726 16.7108 15.5102 16.5517 15.3511L13.1977 11.9992Z"
              fill="#8A8F9F"
            />
          </svg>
        </Button>
      </div>
      <p className="upload-image__text">
        プロフィールに使用する画像のアップロード、画像のトリミングを行うことができます。
      </p>
      {selectedFile ? (
        <div className="upload-image__crop">
          <div style={{ maxWidth: '400px', margin: 'auto' }}>
            <ReactCrop
              crop={crop}
              onChange={c => setCrop(c)}
              onComplete={c => setCrop(c)}
              aspect={1}
            >
              <img
                src={URL.createObjectURL(selectedFile)}
                alt="Selected"
                style={{ maxWidth: '400px', margin: 'auto' }}
              />
            </ReactCrop>
          </div>
        </div>
      ) : image ? (
        <div className="upload-image__crop">
          <div style={{ maxWidth: '400px', margin: 'auto' }}>
            <img
              src={image}
              alt="Selected"
              style={{ maxWidth: '400px', margin: 'auto' }}
            />
          </div>
        </div>
      ) : (
        <div className="upload-image__edit">
          <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="64" height="64" rx="32" fill="#8A8F9F" />
            <g style={{ mixBlendMode: 'multiply' }}>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.9995 32.2469C37.3014 32.2469 41.5995 27.9488 41.5995 22.6469C41.5995 17.3449 37.3014 13.0469 31.9995 13.0469C26.6976 13.0469 22.3995 17.3449 22.3995 22.6469C22.3995 27.9488 26.6976 32.2469 31.9995 32.2469ZM31.9995 57.8469C40.066 57.8469 47.2735 54.0961 51.9693 48.2471C47.2735 42.3978 40.0658 38.6469 31.9991 38.6469C23.9326 38.6469 16.7252 42.3976 12.0293 48.2466C16.7251 54.0959 23.9328 57.8469 31.9995 57.8469Z"
                fill="#8A8F9F"
              />
            </g>
          </svg>
        </div>
      )}
      <div className="upload-image__form">
        <div className="form__item">
          <div className="form__label">
            <p className="upload-image__label-name">ファイルから抽出</p>
            <label className="btn_secondary-s">
              ファイルをアップロード
              <input
                type="file"
                id="file"
                className="form__input--hidden"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
            </label>
            {errorMessage && (
              <p className="form__errorMessage">{errorMessage}</p>
            )}
            <p className="upload-image__form-notion">
              10MBまでのjpeg, pngまたはgifファイルをアップロード可能
            </p>
          </div>
        </div>
      </div>
      <div className="upload-image__form smaller-margin">
        <div className="form__item">
          <div className="modal-edit-btn__wrapper no-absolute">
            <Button className="btn_secondary-l" onClick={closeModal}>
              キャンセル
            </Button>
            <Button
              className="btn"
              onClick={handleSave}
              disabled={!selectedFile}
            >
              保存
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
