import React from 'react'
import { TimelineCardQuery, TimelineReactionMemberQuery } from '../../services'
import CardWrapper from './CardWrapper'
import MemberProfileCard from './MemberProfileCard'

interface UpdateSingleWordCardProps {
  timelineCard: TimelineCardQuery
  myTimelineReactionMemberQuery: TimelineReactionMemberQuery
  onSendMessage: (message: string) => void
}

const UpdateSingleWordCard: React.FC<UpdateSingleWordCardProps> = ({
  timelineCard,
  myTimelineReactionMemberQuery,
  onSendMessage,
}) => {
  const updateSingleWordMember = timelineCard.updateSingleWordCard?.member
  if (!updateSingleWordMember) return null
  return (
    <CardWrapper
      className={'home-timeline__card home-timeline__card-comment'}
      linkTo={`/member-detail/${updateSingleWordMember.memberId}`}
      profileImageUrl={updateSingleWordMember.profileImageUrl}
      title={
        <>
          <span>{updateSingleWordMember.memberName}</span>
          さんがひとことを更新しました
        </>
      }
      createdAt={timelineCard.createdAt}
      timelineCard={timelineCard}
      myTimelineReactionMemberQuery={myTimelineReactionMemberQuery}
      member={updateSingleWordMember}
      onSendMessage={onSendMessage}
    >
      <MemberProfileCard
        member={updateSingleWordMember}
        singleWord={timelineCard.updateSingleWordCard?.singleWord}
        className="home-timeline__card-under-member"
      />
    </CardWrapper>
  )
}

export default UpdateSingleWordCard
