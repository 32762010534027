import { Link } from 'react-router-dom'

const ProfileEditCard: React.FC = () => {
  return (
    <section className="home-sidebar__profile">
      <div className="home-sidebar__profile-image">
        <img
          src="/images/home_sidebar_img.jpg"
          alt="プロフィールを充実させよう"
          width={367}
          height={130}
        />
      </div>
      <div className="home-sidebar__profile-content">
        <h2 className="home-sidebar__profile-title">
          プロフィールを充実させよう
        </h2>
        <p className="home-sidebar__profile-text">
          プロフィールを充実させると、あなたと共通点の多いメンバーに見つけてもらいやすくなります。
        </p>
        <div className="home-sidebar__profile-btn-wrapper">
          <Link to={'/profile'} className="btn btn__secondary">
            プロフィールを編集する
          </Link>
        </div>
      </div>
    </section>
  )
}

export default ProfileEditCard
