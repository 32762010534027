import React, { useState } from 'react'
import { Button } from '../button'

interface TextInputWithButtonProps {
  placeholder?: string
  onSend: (message: string) => void
  buttonText?: string
}

const TextInputWithButton: React.FC<TextInputWithButtonProps> = ({
  placeholder = 'コメントを送る',
  onSend,
  buttonText = '送信',
}) => {
  const [textState, setTextState] = useState('')

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextState(event.target.value)
  }

  const handleSendClick = () => {
    onSend(textState)
    setTextState('')
  }

  return (
    <div
      className="form__label home-timeline__send-cube_btn-wrapper"
      style={{
        // TODO リリースまで非表示
        display: 'none',
      }}
    >
      <textarea
        className="chat__input home-timeline__send-cube_input"
        placeholder={placeholder}
        value={textState}
        onChange={handleInputChange}
      ></textarea>
      <div className="chat-input-container__button">
        <Button
          className="btn chat__submit-btn home-timeline__send-cube_btn"
          onClick={handleSendClick}
          disabled={!textState.trim()}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  )
}

export default TextInputWithButton
