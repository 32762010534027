import React from 'react'
import { CareerQuery } from '../../services'
import { formatDate } from '../../lib/common'
import { Button } from '../button'

interface CareerInfoProps {
  careers: CareerQuery[]
  titleClassName: string
  onAddClick?: () => void
  onEditClick?: (career: CareerQuery) => void
  showAddButton?: boolean
}

export const CareerInfo: React.FC<CareerInfoProps> = ({
  careers,
  titleClassName,
  onAddClick,
  onEditClick,
  showAddButton = false,
}) => {
  return (
    <div>
      <h3 className={titleClassName}>経歴</h3>
      <dl className="career__list">
        {careers?.map((career, index) => (
          <div className="career-list__item" key={index}>
            <dt className="career__term">
              {career.enrollmentPeriodStart || career.enrollmentPeriodEnd ? (
                <>
                  <time>
                    {formatDate(career.enrollmentPeriodStart, 'yyyy年MM月')}
                  </time>
                  〜
                  <br />
                  <time>
                    {career.enrollmentPeriodEnd
                      ? formatDate(career.enrollmentPeriodEnd, 'yyyy年MM月')
                      : career.currentFlg
                      ? '現在'
                      : ''}
                  </time>
                </>
              ) : career.currentFlg ? (
                '現職'
              ) : (
                ''
              )}
            </dt>
            <dd className="career__desc">
              <span
                className="career-desc__company"
                tabIndex={0}
                onClick={() => onEditClick?.(career)}
              >
                {career.companyName}
              </span>
              <span className="career-desc__occupation">
                {career.occupationName}
              </span>
            </dd>
          </div>
        ))}
      </dl>
      {showAddButton && (
        <div className="profile_page-career-btn__wrapper">
          <Button className="profile_page__career-btn" onClick={onAddClick}>
            経歴を追加
          </Button>
        </div>
      )}
    </div>
  )
}
