import React, { FC, useEffect, useState } from 'react'
import {
  AdminControllerService,
  CommonControllerService,
  MemberRoleMap,
  MemberRoleQuery,
} from '../../services'
import apiErrorHandler from '../../api/apiErrorHandler'
import { toast } from 'react-toastify'
import { Button } from '../../components/button'

interface Props {
  closeModal: () => void
}

export const MemberInviteForMailModal: FC<Props> = ({ closeModal }) => {
  const [memberRoleMaps, setMemberRoleMaps] = useState<MemberRoleMap[]>([])

  const [memberRole, setMemberRole] = useState<MemberRoleQuery.memberRole>(
    MemberRoleQuery.memberRole.MEMBER,
  )

  useEffect(() => {
    CommonControllerService.getMemberRoles()
      .then(res => {
        setMemberRoleMaps(res)
      })
      .catch(err => {
        apiErrorHandler(err)
      })
  }, [])

  const createInviteUrlAndCopy = () => {
    AdminControllerService.createWorkspaceInvitationUrl({
      role: memberRole,
    })
      .then(res => {
        // 非同期処理だとSafariでコピーできないため、setTimeoutで同期的に処理する
        new Promise((resolve, reject) => {
          setTimeout(() => {
            navigator.clipboard.writeText(res).then(resolve).catch(reject)
          }, 0)
        })
        toast.success('招待URLを発行してコピーしました。')
        closeModal()
      })
      .catch(apiErrorHandler)
  }

  return (
    <div
      className="contact-info-edit__content"
      role="dialog"
      aria-modal="true"
      aria-labelledby="contact-info-edit__title"
    >
      <div className="modal-title_wrapper">
        <h1 className="modal-edit__title" id="contact-info-edit__title">
          招待URL発行(メールログイン)
        </h1>

        <Button className="btn_modal_close" onClick={closeModal}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            aria-label="Close modal"
          >
            <rect width="24" height="24" rx="12" fill="#EDF0F7" />
            <path
              d="M13.1977 11.9992L16.5496 8.6473C16.7087 8.4885 16.7982 8.27301 16.7984 8.04824C16.7986 7.82346 16.7095 7.60781 16.5507 7.44873C16.3919 7.28965 16.1764 7.20017 15.9516 7.19997C15.7268 7.19977 15.5112 7.28887 15.3521 7.44767L12.0002 10.7996L8.64828 7.44767C8.4892 7.28859 8.27344 7.19922 8.04846 7.19922C7.82349 7.19922 7.60773 7.28859 7.44865 7.44767C7.28957 7.60675 7.2002 7.82251 7.2002 8.04749C7.2002 8.27246 7.28957 8.48822 7.44865 8.6473L10.8006 11.9992L7.44865 15.3511C7.28957 15.5102 7.2002 15.726 7.2002 15.951C7.2002 16.1759 7.28957 16.3917 7.44865 16.5508C7.60773 16.7098 7.82349 16.7992 8.04846 16.7992C8.27344 16.7992 8.4892 16.7098 8.64828 16.5508L12.0002 13.1989L15.3521 16.5508C15.5112 16.7098 15.727 16.7992 15.9519 16.7992C16.1769 16.7992 16.3927 16.7098 16.5517 16.5508C16.7108 16.3917 16.8002 16.1759 16.8002 15.951C16.8002 15.726 16.7108 15.5102 16.5517 15.3511L13.1977 11.9992Z"
              fill="#8A8F9F"
            />
          </svg>
        </Button>
      </div>

      <div className="save-cube__form">
        <div className="form__item"></div>

        <hr className="separator" />
        <small>※招待URLは発行から7日間のみ有効です。</small>

        <div className="form__item">
          <label className="form__label">権限</label>
          <div className="form__select-wrapper">
            <select
              className="form__select form__role-select"
              value={memberRole}
              onChange={e =>
                setMemberRole(e.target.value as MemberRoleQuery.memberRole)
              }
            >
              {memberRoleMaps.map((memberRoleMap, index) => (
                <option key={index} value={memberRoleMap.name}>
                  {memberRoleMap.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="modal-edit-btn__wrapper contact-info-edit-btn__wrapper">
          <Button
            className="btn btn_modal-edit_save"
            onClick={createInviteUrlAndCopy}
          >
            招待URL発行してコピー
          </Button>
        </div>
      </div>
    </div>
  )
}
