import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { ToggleReactionButton } from '../toggleReactionButton'
import {
  SimpleMemberQuery,
  TimelineCardQuery,
  TimelineReactionMemberQuery,
} from '../../services'
import TextInputWithButton from '../TextInputWithButton'

interface CardWrapperProps {
  className: string
  linkTo: string
  profileImageUrl?: string
  title: JSX.Element
  cardText?: string
  createdAt?: string
  children: ReactNode
  timelineCard?: TimelineCardQuery
  myTimelineReactionMemberQuery?: TimelineReactionMemberQuery
  member: SimpleMemberQuery
  onSendMessage: (message: string) => void
}

const CardWrapper: React.FC<CardWrapperProps> = ({
  className,
  linkTo,
  profileImageUrl,
  title,
  cardText,
  createdAt,
  children,
  timelineCard,
  myTimelineReactionMemberQuery,
  member,
  onSendMessage,
}) => {
  return (
    <li className={className}>
      <Link className="home-timeline__card-link" to={linkTo}>
        <div className="home-timeline__card-upper">
          <div className="home-timeline__card-upper-avater">
            <img
              src={
                profileImageUrl ? profileImageUrl : '/icons/avatar-sample.png'
              }
              alt="プロフィール画像"
              width={32}
              height={32}
            />
          </div>
          <div className="home-timeline__card-upper-content">
            <h2 className="home-timeline__card-upper-title">{title}</h2>
            {cardText && (
              <p className="home-timeline__card-upper-text">{cardText}</p>
            )}
            <span className="home-timeline__card-posttime">{createdAt}</span>
          </div>
        </div>

        {children}
      </Link>
      {timelineCard && myTimelineReactionMemberQuery && (
        <ToggleReactionButton
          timelineCard={timelineCard}
          myTimelineReactionMemberQuery={myTimelineReactionMemberQuery}
        />
      )}
      <TextInputWithButton
        onSend={onSendMessage}
        placeholder={`${member.memberName}さんへのコメント`}
      />
    </li>
  )
}

export default CardWrapper
