import React from 'react'
import { ChatMessageQuery } from '../../services'
import { getChatMessageAt } from '../../lib/chatUtils'

interface ChatMessageItemProps {
  message: ChatMessageQuery
  isFromMe: boolean
  scrollRef?: React.RefObject<HTMLLIElement>
}

// 改行文字を変換
const convertNewlinesToBr = (text: string): React.ReactNode => {
  return text.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      {index < text.split('\n').length - 1 && <br />}
      {/* 最後の行以外に <br> を挿入 */}
    </React.Fragment>
  ))
}

// リンクの場合、aタグで表示
const renderMessage = (message: ChatMessageQuery) => {
  // テキスト内のリンク確認
  const urlRegex = /(https?:\/\/[^\s]+)/g
  const links = message.message.match(urlRegex)

  if (links) {
    return (
      <div className="chat__list__item__comment">
        {message.message.split(urlRegex).map((part, index) =>
          index % 2 === 0 ? (
            <span key={index}>{convertNewlinesToBr(part)}</span>
          ) : (
            <a
              key={index}
              href={part}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              {part}
            </a>
          ),
        )}
      </div>
    )
  } else {
    return (
      <p className="chat__list__item__comment">
        {convertNewlinesToBr(message.message)}
      </p>
    )
  }
}

const ChatMessageItem: React.FC<ChatMessageItemProps> = ({
  message,
  isFromMe,
  scrollRef,
}) => {
  return (
    <li
      className={`chat__list__item ${isFromMe ? 'fromMe' : 'fromOther'}`}
      ref={scrollRef}
    >
      {!isFromMe && (
        <p className="chat__list__item__name">{message.memberName}</p>
      )}
      <div className="chat__list__item__container">
        {!isFromMe && (
          <img
            className="chat__list__item__icon"
            src={message.profileImageUrl || '/icons/avatar-sample.png'}
            alt="プロフィール画像"
          />
        )}
        <div className="chat__list__item__content">
          {renderMessage(message)}
          <time className="chat__list__item__time">
            {getChatMessageAt(message.datetime)}
          </time>
        </div>
      </div>
    </li>
  )
}

export default ChatMessageItem
