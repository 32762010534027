import React, { FC, useEffect, useState } from 'react'
import { Head } from '../../layouts/head'
import { Header } from '../../layouts/header'
import { Footer } from '../../layouts/footer'
import { HeaderType } from '../../enums/HeaderType'
import { useNavigate, useSearchParams } from 'react-router-dom'
import env from '../../config/env'
import {
  ApiError,
  MemberControllerService,
  MemberLogoutControllerService,
} from '../../services'
import apiErrorHandler from '../../api/apiErrorHandler'

export const Login: FC = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const loginError = searchParams.get('error') as string

  const [privacyPolicy, setPrivacyPolicy] = useState(false)

  const togglePrivacyPolicy = () => {
    setPrivacyPolicy(!privacyPolicy)
  }

  const signInWithGoogle = () => {
    if (!privacyPolicy) return
    window.location.href = `${env.BACKEND_BASE_URL}/oauth2/authorization/google`
  }
  const signInWithMicrosoft = () => {
    if (!privacyPolicy) return
    window.location.href = `${env.BACKEND_BASE_URL}/oauth2/authorization/outlook`
  }

  // セッションが残っている場合は、ログアウトAPIの呼び出し
  useEffect(() => {
    const checkAndLogout = async () => {
      const loggedInMember = await MemberControllerService.getLoggedInMember()
      if (loggedInMember && loggedInMember.memberId) {
        try {
          await MemberLogoutControllerService.logout()
        } catch (err) {
          apiErrorHandler(err as ApiError)
        }
      }
    }

    checkAndLogout()
  }, [])

  return (
    <>
      <Head />
      <Header headerType={HeaderType.NOT_LOGIN} />
      <main className="main">
        <div className="form__wrapper">
          <h1 className="pagetitle">ログイン</h1>
          {/* パスワード認証追加まで非表示 */}
          {/*
          <form action="" method="post" className="form">
            <p className="form__item">
              <label className="form__label">
                メールアドレス
                <input
                  type="email"
                  name="email"
                  placeholder="sample@parks.co.jp"
                />
              </label>
            </p>
            <p className="form__item">
              <label className="form__label">
                パスワード
                <input
                  type="password"
                  name="password"
                  placeholder="○文字以上の英数字"
                />
              </label>
            </p>
            <p className="form__check">
              <input type="checkbox" id="unmask" name="unmask" />
              <label htmlFor="unmask">パスワードを表示</label>
            </p>
            <p className="form__link">
              <a href="#">パスワードをお忘れですか？</a>
            </p>
            <p className="form__submit">
              <button type="submit" className="btn btn--disabled">
                ログイン
              </button>
            </p>
            <p className="form__link signup">
              <a href="#">はじめてご利用の方は新規登録へ</a>
            </p>
          </form>
          */}

          <hr className="separator" />

          {loginError && (
            <>
              <p className="form__item">
                {loginError === 'noCalendarScope' && (
                  <span className="form__error">
                    ※Googleカレンダーへのアクセスを許可してログインしてください
                  </span>
                )}
                {loginError === 'notExists' && (
                  <span className="form__error">
                    ※ログインしようとしたユーザーが見つかりませんでした
                  </span>
                )}
              </p>
              <hr className="separator" />
            </>
          )}

          <p className="form__check form__check-center">
            <input
              type="checkbox"
              id="privacy-policy"
              name="privacy-policy"
              onChange={togglePrivacyPolicy}
              checked={privacyPolicy}
            />
            <label htmlFor="privacy-policy">
              プライバシーポリシーに同意する
            </label>
          </p>
          <p className="form__link signup">
            <a href="/privacy-policy" target="_blank">
              プライバシーポリシー
            </a>
          </p>

          <hr className="separator" />

          <div className="btn-wrapper">
            <a className="btn btn--sns btn--google" onClick={signInWithGoogle}>
              Googleでログイン
            </a>
            <a
              className="btn btn--sns btn--office"
              onClick={signInWithMicrosoft}
            >
              Office365でログイン
            </a>
          </div>

          <div className="custom-mail-link">
            <a
              onClick={() => navigate('/login/mail')}
            >
              メールアドレスでログイン
            </a>

          </div>

          <p className="form__item">
            <span className="form__subtext">
              ログインボタンが表示されない場合は広告ブロックを無効にしてください
            </span>
          </p>
        </div>
      </main>
      <Footer />
    </>
  )
}
