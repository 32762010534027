import { FC, useEffect, useState } from 'react'
import {
  ChatMessageReadControllerService,
  MemberControllerService,
  MemberRoleQuery,
  TimelineControllerService,
} from '../../services'
import { useRecoilState } from 'recoil'
import { chatMessageUnreadCountState } from '../../lib/atoms'
import { NavLink } from 'react-router-dom'
import { Button } from '../../components/button'
import { useModal } from '../../contexts/modalContext'
import { DataOptionsModal } from '../../modals/dataOptionsModal'
import { CubeSendModal } from '../../modals/cubeSendModal'

export const SideNavi: FC = () => {
  const [memberRole, setMemberRole] = useState<MemberRoleQuery>(
    {} as MemberRoleQuery,
  )
  const [timelineCount, setTimelineCount] = useState(0)

  const [chatMessageUnreadCount, setChatMessageUnreadCount] = useRecoilState(
    chatMessageUnreadCountState,
  )
  const { openModal } = useModal()

  useEffect(() => {
    MemberControllerService.getMemberRole().then(res => {
      setMemberRole(res)
    })

    ChatMessageReadControllerService.getUnreadChatMessageCount().then(res => {
      setChatMessageUnreadCount(res)
    })

    // ホームの場合はスキップ
    if (location.pathname === '/') return
    TimelineControllerService.getCountUnread().then(res => {
      setTimelineCount(res.count)
    })
  }, [])
  return (
    <>
      {/* PC */}
      <nav className="sidenav">
        <div className="sidenav_head_block">
          <h1 className="parks_logo">
            <a href="/">
              <img src="/images/parks_logo.svg" alt="parks" />
            </a>
          </h1>

          <ul className="sidenav-list">
            <li className="sidenav-list__item sidenav-list__item01">
              <a href="/" className="sidenav-item__link">
                {' '}
                ホーム{' '}
                {timelineCount > 0 && (
                  <span className="sidenav-item__timeline-icon">
                    {timelineCount}
                  </span>
                )}
              </a>
            </li>

            <li className="sidenav-list__item sidenav-list__item02">
              <a href="/member-list" className="sidenav-item__link">
                {' '}
                メンバー{' '}
              </a>
            </li>

            <li className="sidenav-list__item sidenav-list__item03">
              <a href="/chat-list" className="sidenav-item__link">
                {' '}
                チャット{' '}
                {chatMessageUnreadCount > 0 && (
                  <span className="sidenav-item__timeline-icon">
                    {chatMessageUnreadCount > 99 ? 99 : chatMessageUnreadCount}
                  </span>
                )}
              </a>
            </li>
            <li className="sidenav-list__item sidenav-list__item05">
              <a href="/cube-summary" className="sidenav-item__link">
                {' '}
                データ
              </a>
            </li>
          </ul>
          <ul className="sidenav-list__item">
            {[
              { to: '/cube-summary', text: 'サマリー' },
              { to: '/ranking', text: 'ランキング' },
            ].map((item, index) => (
              <li key={index}>
                <NavLink to={item.to} className="subcategories-item__link">
                  {item.text}
                </NavLink>
              </li>
            ))}
          </ul>
          <div className="sidenav_cube_btn">
            <Button
              className="btn btn-icon"
              imageUrl="/images/icon_cube-send.svg"
              onClick={() => openModal(CubeSendModal)}
            >
              キューブを送る
            </Button>
          </div>
        </div>
        <div className="sidenav_foot_block">
          <ul className="sidenav-list">
            {memberRole.memberRole === MemberRoleQuery.memberRole.ADMIN && (
              <li className="sidenav-list__item sidenav-list__item04">
                <a href="/admin/member-list" className="sidenav-item__link">
                  {' '}
                  管理{' '}
                </a>
              </li>
            )}
          </ul>
        </div>
      </nav>

      {/* SP */}
      <nav className="nav">
        <ul className="nav__list">
          <li className="nav-list__item">
            <a href="/" className="nav-item__link">
              <svg
                width="29"
                height="28"
                fill="none"
                className="icon_nav_home icon_nav"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12.167 22.167v-5.834h4.667v5.834A1.17 1.17 0 0 0 18 23.333h3.5a1.17 1.17 0 0 0 1.167-1.166V14h1.983c.537 0 .794-.665.385-1.015L15.282 4.2a1.176 1.176 0 0 0-1.564 0l-9.753 8.785A.581.581 0 0 0 4.35 14h1.983v8.167A1.17 1.17 0 0 0 7.5 23.333H11a1.17 1.17 0 0 0 1.167-1.166Z" />
              </svg>

              <span className="nav-item__inline nav-item__inline-home">
                {' '}
                ホーム{' '}
                {timelineCount > 0 && (
                  <span className="sidenav-item__timeline-icon">
                    {timelineCount}
                  </span>
                )}
              </span>
            </a>
          </li>

          <li className="nav-list__item">
            <a href="/member-list" className="nav-item__link">
              <svg
                width="29"
                height="28"
                fill="none"
                className="icon_nav_member icon_nav"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M14.625 7a3.675 3.675 0 1 1 0 7.35 3.675 3.675 0 0 1 0-7.35Zm-7.35 2.625a3.1 3.1 0 0 1 1.606.441 5.813 5.813 0 0 0 1.187 4.158 3.147 3.147 0 0 1-5.943-1.449 3.15 3.15 0 0 1 3.15-3.15Zm14.7 0a3.15 3.15 0 1 1 0 6.3 3.147 3.147 0 0 1-2.793-1.701 5.813 5.813 0 0 0 1.186-4.158 3.1 3.1 0 0 1 1.607-.441ZM7.8 20.387c0-2.173 3.055-3.937 6.825-3.937s6.825 1.764 6.825 3.938v1.837H7.8v-1.838Zm-5.775 1.838V20.65c0-1.46 1.984-2.688 4.672-3.045-.62.714-.997 1.701-.997 2.782v1.838H2.025Zm25.2 0H23.55v-1.838c0-1.081-.378-2.068-.998-2.782 2.688.357 4.673 1.585 4.673 3.045v1.575Z" />
              </svg>
              <span className="nav-item__inline nav-item__inline-member">
                {' '}
                メンバー{' '}
              </span>
            </a>
          </li>

          <li className="nav-list__item">
            <a href="/chat-list" className="nav-item__link">
              <svg
                width="29"
                height="28"
                fill="none"
                className="icon_nav_chat icon_nav"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M22.4864 7.13832L20.4846 20.1227C20.4533 20.4356 20.2657 20.7172 19.9842 20.8736C19.8278 20.9362 19.6714 20.9988 19.4837 20.9988C19.3586 20.9988 19.2335 20.9675 19.1084 20.9049L15.2925 19.3093L13.6973 21.6871C13.5722 21.9061 13.3532 22 13.1343 22C12.7902 22 12.5087 21.7184 12.5087 21.3742V18.3706C12.5087 18.1203 12.5713 17.9013 12.6964 17.7449L19.515 8.9843L10.3193 17.2755L7.09766 15.9302C6.7536 15.7737 6.50337 15.4609 6.50337 15.0541C6.4721 14.6161 6.65976 14.3032 7.00382 14.1155L21.0163 6.13711C21.3291 5.94938 21.767 5.94938 22.0798 6.1684C22.3926 6.38741 22.549 6.76286 22.4864 7.13832Z" />
              </svg>
              <span className="nav-item__inline nav-item__inline-chat">
                {' '}
                チャット{' '}
                {chatMessageUnreadCount > 0 && (
                  <span className="sidenav-item__timeline-icon">
                    {chatMessageUnreadCount > 99 ? 99 : chatMessageUnreadCount}
                  </span>
                )}
              </span>
            </a>
          </li>

          <li className="nav-list__item">
            <Button
              className="nav-item__link"
              onClick={() => openModal(DataOptionsModal)}
            >
              <svg
                width="29"
                height="28"
                fill="none"
                className="icon_nav_data icon_nav"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 26 25"
              >
                <g transform="translate(4, 5)">
                  <path d="M15.2341 0.34375C14.7437 0.343542 14.2665 0.501035 13.8741 0.792558C13.4818 1.08408 13.1956 1.49388 13.0585 1.96037C12.9214 2.42686 12.9408 2.92482 13.1139 3.37942C13.287 3.83402 13.6043 4.22069 14.0182 4.48132L12.5396 9.60814C12.4937 9.60524 12.4477 9.60372 12.4017 9.60358C11.9143 9.60305 11.4399 9.75908 11.0495 10.0483L8.31793 7.79311C8.44726 7.41298 8.4714 7.00547 8.38786 6.61297C8.30432 6.22047 8.11614 5.85731 7.8429 5.56129C7.56966 5.26526 7.22135 5.04717 6.83419 4.92972C6.44704 4.81226 6.03518 4.79973 5.64148 4.89342C5.24778 4.98711 4.88661 5.18361 4.59555 5.46247C4.30449 5.74132 4.09416 6.09235 3.98646 6.47903C3.87876 6.86571 3.87761 7.27392 3.98314 7.66119C4.08867 8.04846 4.29702 8.40065 4.58651 8.6811L2.98297 11.8585C2.91274 11.8519 2.84225 11.8485 2.77171 11.8484C2.22887 11.8477 1.70378 12.0398 1.29174 12.39C0.879706 12.7401 0.608033 13.2249 0.526037 13.7566C0.444042 14.2882 0.557159 14.8313 0.844857 15.2873C1.13255 15.7434 1.57576 16.0821 2.09403 16.242C2.6123 16.402 3.17128 16.3725 3.6695 16.159C4.16773 15.9455 4.57217 15.5621 4.80942 15.0784C5.04668 14.5947 5.10101 14.0428 4.96256 13.5228C4.82412 13.0028 4.50207 12.5492 4.05487 12.2444L5.57019 9.24234C5.93828 9.34294 6.3261 9.34952 6.69745 9.26147C7.06881 9.17342 7.41159 8.9936 7.69382 8.7388L10.3385 10.9223C10.1589 11.3144 10.097 11.7494 10.16 12.1754C10.223 12.6014 10.4084 13.0005 10.694 13.325C10.9796 13.6496 11.3533 13.8859 11.7708 14.0059C12.1882 14.1258 12.6316 14.1243 13.0482 14.0015C13.4647 13.8787 13.8369 13.6398 14.1202 13.3133C14.4035 12.9868 14.5861 12.5864 14.6461 12.16C14.7061 11.7336 14.6411 11.299 14.4588 10.9082C14.2764 10.5173 13.9845 10.1867 13.6177 9.95563L15.0962 4.8288C15.1419 4.83154 15.1878 4.83336 15.2341 4.83336C15.8351 4.83336 16.4114 4.59686 16.8363 4.17588C17.2613 3.75489 17.5 3.18392 17.5 2.58856C17.5 1.9932 17.2613 1.42222 16.8363 1.00124C16.4114 0.580256 15.8351 0.34375 15.2341 0.34375Z" />
                </g>
              </svg>
              <span className="nav-item__inline nav-item__inline-data">
                データ
              </span>
            </Button>
          </li>
        </ul>
      </nav>
    </>
  )
}
