import React from 'react'
import { formatDate } from '../../lib/common'
import { MemberQuery } from '../../services'

interface BasicInfoProps {
  member: MemberQuery
  titleClassName: string
  onClick?: () => void
  dateOfBirthShowFlg?: boolean
}

export const BasicInfo: React.FC<BasicInfoProps> = ({
  member,
  titleClassName,
  onClick,
  dateOfBirthShowFlg,
}) => {
  return (
    <div>
      <h3 className={titleClassName} tabIndex={0} onClick={onClick}>
        基本情報
      </h3>
      <dl className="base-profile__list">
        <div className="base-profile-list__item">
          <dt className="base-profile__term">生年月日</dt>
          <dd className="base-profile__desc">
            {dateOfBirthShowFlg === false || !member.dateOfBirth
              ? '-'
              : formatDate(member.dateOfBirth, 'yyyy年MM月dd日')}
          </dd>
        </div>
        <div className="base-profile-list__item">
          <dt className="base-profile__term">入社日</dt>
          <dd className="base-profile__desc">
            {member.dateOfEntry
              ? formatDate(member.dateOfEntry, 'yyyy年MM月dd日')
              : '-'}
          </dd>
        </div>
        <div className="base-profile-list__item">
          <dt className="base-profile__term">メールアドレス</dt>
          <dd className="base-profile__desc">{member.email}</dd>
        </div>
      </dl>
    </div>
  )
}
