import React, { FC } from 'react'
import { Head } from '../../layouts/head'
import { Header } from '../../layouts/header'
import { SideNavi } from '../../layouts/sideNavi'

export const Notification: FC = () => {
  return (
    <>
      <Head />
      <Header />
      <SideNavi />
      <main className="main-sidenav notification_page-main-sidenav">
        <ul className="notification-list">
          <li className="notification-list_item">
            <div className="notification-list_icon">
              <img src="/images/avatar03.png" alt="" width={32} height={32} />
              <img
                src="/icons/icon_mention.svg"
                alt=""
                width={16}
                height={16}
              />
            </div>
            <div className="notification-list_text">
              <p>
                <span>岡野 太郎</span>さんがあなたにメンションしました
              </p>
              <p className="notification-list_time">約1時間前</p>
            </div>
          </li>
          <li className="notification-list_item">
            <div className="notification-list_icon">
              <img
                src="/icons/avatar-sample.png"
                alt=""
                width={32}
                height={32}
              />
              <img
                src="/icons/icon_comment.svg"
                alt=""
                width={16}
                height={16}
              />
            </div>
            <div className="notification-list_text">
              <p>
                <span>真田 進</span>さんがコメントしました
              </p>
              <p className="notification-list_time">約1時間前</p>
            </div>
          </li>
          {[
            'GRINNING_SQUINTING_FACE',
            'HEART',
            'LOUDLY_CRYING_FACE',
            'SMILE_FACE',
            'THUMBS_UP',
          ].map((reaction, index) => (
            <li key={index} className="notification-list_item">
              <div className="notification-list_icon">
                <img
                  src="/images/home_avatar03.jpg"
                  alt=""
                  width={32}
                  height={32}
                />
                <img
                  src={`/images/reaction-${reaction}.svg`}
                  width={16}
                  height={16}
                />
              </div>
              <div className="notification-list_text">
                <p>
                  <span>加藤 はな</span>さんと他3人がスタンプを押しました
                </p>
                <p className="notification-list_time">約1時間前</p>
              </div>
            </li>
          ))}
          <li className="notification-list_item notification-list_item-active">
            <div className="notification-list_icon">
              <img
                src="/images/home_avatar03.jpg"
                alt=""
                width={32}
                height={32}
              />
              <img src="/icons/icon_cube.svg" alt="" width={16} height={16} />
            </div>
            <div className="notification-list_text notification-list_text-active">
              <p>
                <span>加藤 はな</span>さんがあなたにキューブを送りました
              </p>
              <p className="notification-list_time">約1時間前</p>
            </div>
          </li>
        </ul>
      </main>
    </>
  )
}
