import React, { FC } from 'react'
import { TextInput } from '../../components/textInput'
import { MemberUpdateForm } from '../../services'
import { Button } from '../../components/button'

interface Props {
  memberUpdateForm: MemberUpdateForm
  setMemberUpdateForm: React.Dispatch<React.SetStateAction<MemberUpdateForm>>
  navigateToPreviousPage: () => void
  navigateToNextPage: () => void
}

export const AdditionalInfoForm: FC<Props> = ({
  memberUpdateForm,
  setMemberUpdateForm,
  navigateToPreviousPage,
  navigateToNextPage,
}) => {
  // データ更新
  const updateMemberData = (key: string, value: any) => {
    setMemberUpdateForm(prevData => ({
      ...prevData,
      [key]: value,
    }))
  }
  return (
    <div className="form__wrapper form__wrapper--wide form__wrapper--square">
      <div className="form form--wide">
        <section className="form__section">
          <h2 className="form__section-title">プロフィール登録</h2>
          <p className="form__item form__item--mt-large">
            <TextInput
              label="主な業務内容"
              placeholder="主な業務内容を入力"
              value={memberUpdateForm.businessContent}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                updateMemberData('businessContent', event.target.value)
              }}
              textarea
              maxLength={1000}
            />
          </p>
          <p className="form__item form__item--mt-large">
            <TextInput
              label="ひとこと"
              placeholder="例）UXライティングについていつでも相談してください！"
              value={memberUpdateForm.singleWord}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updateMemberData('singleWord', event.target.value)
              }}
              textarea
              maxLength={255}
            />
          </p>
        </section>

        <div className="form__submit">
          <Button className="btn" onClick={navigateToNextPage}>
            つぎへ
          </Button>
          <Button
            className="btn btn_secondary-l with-margin-top"
            onClick={navigateToPreviousPage}
          >
            前に戻る
          </Button>
        </div>
      </div>
    </div>
  )
}
