import React from 'react'
import { MemberQuery } from '../../services'
import { pickupCubes } from '../../lib/common'
import ChatButton from '../button/chatButton'
import { Link } from 'react-router-dom'
import CubeList from './CubeList'

interface recommendMemberCardProps {
  member: MemberQuery
}

const RecommendMemberCard: React.FC<recommendMemberCardProps> = ({
  member,
}) => {
  const {
    memberId,
    profileImageUrl,
    memberName,
    lengthOfService,
    position,
    cubes,
  } = member

  return (
    <Link to={`/member-detail/${memberId}`}>
      <div className="member-card member-card--with-border">
        <div className="member-card__header">
          <div className="avatar-block member-card__left">
            <div className="avatar-block__img-wrapper">
              <img
                src={profileImageUrl || '/icons/avatar-sample.png'}
                alt="プロフィール画像"
              />
            </div>
            <div className="avatar-block__body">
              <div>
                <span className="avatar-block__name">{memberName}</span>
                <br className="avatar-block__u-sp" />
                <span className="avatar-block__tenure">{lengthOfService}</span>
              </div>
              <p className="avatar-block__position">{position?.positionName}</p>
            </div>
          </div>
          <div className="member-card__right">
            <ChatButton
              className="member-card__btn member-card__btn--talk"
              memberId={memberId}
            />
          </div>
        </div>
        <CubeList
          cubes={pickupCubes(cubes)}
          className="cubes member-card__footer"
          itemClassName="cube"
        />
      </div>
    </Link>
  )
}

export default RecommendMemberCard
