import React from 'react'
import { MemberCubeQuery, TimelineCardQuery } from '../../services'
import IndividualUpdateCubeCard from './IndividualUpdateCubeCard'
import GiftUpdateCubeCard from './GiftUpdateCubeCard'
import { Cube } from '../cube'

interface UpdateCubeCardProps {
  timelineCard: TimelineCardQuery
  memberId: number
  memberCubes: MemberCubeQuery[]
  index: number
  onSendMessage: (message: string) => void
}

const UpdateCubeCard: React.FC<UpdateCubeCardProps> = ({
  timelineCard,
  memberId,
  memberCubes,
  index,
  onSendMessage,
}) => {
  const memberCube = timelineCard.updateCubeCard?.linkMemberCubes[0]
  const from = timelineCard.updateCubeCard?.from
  const to = timelineCard.updateCubeCard?.to

  if (!to || !from) return null

  const commonMessage =
    to.memberId !== memberId &&
    memberCubes?.some(
      (cube: MemberCubeQuery) => cube.cubeId === memberCube?.cubeId,
    )
      ? 'あなたと同じ'
      : ''
  let suffix = ''
  let individualFlg = false

  if (
    from.memberId === memberId && // 送ったのが自分
    to.memberId === memberId // 受け取ったのが自分
  ) {
    // 個人型：自分から自分
    individualFlg = true
    suffix = `プロフィールにキューブを追加しました👏`
  } else if (
    from.memberId === memberId && // 送ったのが自分
    to.memberId !== memberId // 受け取ったのが他人
  ) {
    // 複数人型：自分から他人
    suffix = `${commonMessage}キューブを送りました👏`
  } else if (
    from.memberId !== memberId && // 送ったのが他人
    to.memberId === memberId // 受け取ったのが自分
  ) {
    // 複数人型：他人から自分
    suffix = `${commonMessage}キューブを送りました👏`
  } else if (
    from.memberId !== memberId && // 送ったのが他人
    to.memberId !== memberId && // 受け取ったのが他人
    from.memberId === to.memberId // 送受信が同一人物
  ) {
    // 個人型：他人から他人（送受信が同一人物）
    individualFlg = true
    suffix = `プロフィールに${commonMessage}キューブを追加しました👏`
  } else if (
    from.memberId !== memberId && // 送ったのが他人
    to.memberId !== memberId && // 受け取ったのが他人
    from.memberId !== to.memberId // 送受信が別人物
  ) {
    // 複数人型：他人から他人（送受信が別人物）
    suffix = `${commonMessage}キューブを送りました👏`
  }

  // キューブをカテゴリーごとにグルーピング
  const cubeMap = new Map<string, MemberCubeQuery[]>()
  timelineCard.updateCubeCard?.linkMemberCubes?.forEach(cube => {
    const cubeCategoryName = cube.cubeCategory.cubeCategoryName
    if (cubeMap.has(cubeCategoryName)) {
      cubeMap.get(cubeCategoryName)?.push(cube)
    } else {
      cubeMap.set(cubeCategoryName, [cube])
    }
  })

  // メッセージをグルーピング
  const messageMap = new Map<string | undefined, number[]>()
  timelineCard.updateCubeCard?.messages.forEach(messageQuery => {
    const message = messageQuery.message
    if (messageMap.has(message)) {
      const memberCubeIds = messageMap.get(message)
      if (memberCubeIds) {
        memberCubeIds.push(messageQuery.memberCubeId)
        messageMap.set(message, memberCubeIds)
      }
    } else {
      messageMap.set(message, [messageQuery.memberCubeId])
    }
  })

  const createCubeView = () => {
    return Array.from(messageMap).map(([message, memberCubeIds], index1) => (
      <React.Fragment key={index1}>
        {Array.from(cubeMap).map(
          ([categoryName, cubes], index2) =>
            cubes.some(cube => memberCubeIds.includes(cube.memberCubeId)) && (
              <React.Fragment key={index2}>
                <h2 className="cube__category">{categoryName}</h2>
                <ul className="cube__list">
                  {[...cubes]
                    .filter(cube => memberCubeIds.includes(cube.memberCubeId))
                    .sort((a, b) => a.memberCubeId - b.memberCubeId)
                    .map(cube => (
                      <Cube
                        memberId={to.memberId}
                        cube={cube}
                        key={cube.cubeId}
                        deleteButtonEnabled={
                          // 自分が最初に送ったキューブの場合は削除ボタンを表示する
                          cube.giftMembers[0]?.memberId === memberId
                        }
                      />
                    ))}
                </ul>
              </React.Fragment>
            ),
        )}
        {message && <p className="home-timeline__card-upper-text">{message}</p>}
        {/* 次の要素が存在する場合は仕切り線を表示 */}
        {Array.from(messageMap)[index1 + 1] && (
          <hr className="home-timeline__card-posttime" />
        )}
      </React.Fragment>
    ))
  }

  return individualFlg ? (
    <IndividualUpdateCubeCard
      timelineCard={timelineCard}
      member={to}
      index={index}
      suffix={suffix}
      onSendMessage={onSendMessage}
    >
      {createCubeView()}
    </IndividualUpdateCubeCard>
  ) : (
    <GiftUpdateCubeCard
      timelineCard={timelineCard}
      member={to}
      giftMember={from}
      index={index}
      suffix={suffix}
      onSendMessage={onSendMessage}
    >
      {createCubeView()}
    </GiftUpdateCubeCard>
  )
}

export default UpdateCubeCard
