import React, { FC } from 'react'

interface Props {
  publicFlg?: boolean
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
}

export const PublishingSettingInput: FC<Props> = ({ publicFlg, onChange }) => {
  return (
    <>
      <label className="form__label">公開範囲</label>
      <div className="form__select-wrapper form__public-range-wrapper">
        <select
          name="form__public-range"
          className="form__select"
          onChange={onChange}
          value={publicFlg ? 'true' : 'false'}
        >
          <option value="true">全員</option>
          <option value="false">自分のみ</option>
        </select>
      </div>
    </>
  )
}
