/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TimelineQuery } from '../models/TimelineQuery';
import type { TimelineUnreadCount } from '../models/TimelineUnreadCount';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TimelineControllerService {

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static createTimelineJoin(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/timeline/join',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param page
     * @param limit
     * @returns TimelineQuery OK
     * @throws ApiError
     */
    public static getTimeline(
        page: number = 1,
        limit: number = 20,
    ): CancelablePromise<TimelineQuery> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/timeline',
            query: {
                'page': page,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @returns TimelineUnreadCount OK
     * @throws ApiError
     */
    public static getCountUnread(): CancelablePromise<TimelineUnreadCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/timeline/unread/count',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}
