import { ChangeEvent, FC, useEffect, useState } from 'react'
import {
  MemberCubeQuery,
  TimelineControllerService,
  TimelineReactionMemberQuery,
  TimelineCardQuery,
  MemberControllerService,
} from '../../services'
import _ from 'lodash'
import { toast } from 'react-toastify'
import Loading from 'react-loading'
import SingleWordInput from '../../components/SingleWordInput'
import JoinCard from '../../components/timeLine/JoinCard'
import UpdateSingleWordCard from '../../components/timeLine/UpdateSingleWordCard'
import UpdateCubeCard from '../../components/timeLine/UpdateCubeCard'
import apiErrorHandler from '../../api/apiErrorHandler'

interface TimelineProps {
  memberId: number
  memberName: string
  singleWord: string | undefined
  profileImageUrl: string | undefined
  positionName: string | undefined
  memberCubes: MemberCubeQuery[]
  closeModal?: () => void
}

const Timeline: FC<TimelineProps> = ({
  memberId,
  memberName,
  singleWord,
  profileImageUrl,
  positionName,
  memberCubes,
  closeModal,
}) => {
  const [timelineCards, setTimelineCards] = useState<TimelineCardQuery[]>([])
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [isFetching, setIsFetching] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const [singleWordPlaceholder, setSingleWordPlaceholder] = useState(singleWord)
  const [singleWordState, setSingleWordState] = useState('')

  const myTimelineReactionMemberQuery = {
    memberId: memberId,
    memberName: memberName,
    profileImageUrl: profileImageUrl,
    positionName: positionName,
  } as TimelineReactionMemberQuery

  // 初回レンダリング時に1ページ目のデータを取得
  useEffect(() => {
    const defaultPage = 1
    TimelineControllerService.getTimeline(defaultPage).then(res => {
      setIsLoading(false)
      if (res.timelineCards.length === 0) {
        setHasMore(false)
      } else {
        setTimelineCards(res.timelineCards)
        setPage(defaultPage + 1)
        setHasMore(true)
      }
    })
  }, [closeModal, singleWordPlaceholder])

  // ページング用のデータを取得
  const fetchData = () => {
    setIsFetching(true)
    TimelineControllerService.getTimeline(page).then(res => {
      if (res.timelineCards.length === 0) {
        setHasMore(false)
      } else {
        setTimelineCards(oldData => [...oldData, ...res.timelineCards])
        setPage(oldPage => oldPage + 1)
      }
      setIsFetching(false)
    })
  }

  // スクロール位置の検出
  useEffect(() => {
    const handleScroll = _.throttle(() => {
      if (
        window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight
      ) {
        return
      }
      // 追加のデータが無い場合、またはデータをフェッチ中の場合は処理を終了
      if (!hasMore || isFetching) {
        return
      }
      fetchData()
    }, 1000) // 1秒間に最大1回だけイベントハンドラが実行されるようにスロットリング

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [page, hasMore, isFetching])

  const updateSingleWord = () => {
    const newSingleWord = singleWordState
    MemberControllerService.updateMember({
      singleWord: newSingleWord,
    })
      .then(() => {
        toast.success('ひとことを更新しました')
        setSingleWordPlaceholder(newSingleWord)
        setSingleWordState('')
      })
      .catch(apiErrorHandler)
  }

  const changeSingleWord = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setSingleWordState(event.target.value)
  }

  const sendMessage = (message: string) => {
    console.log('コメント送信:', message)
    // コメント送信ロジック追加
  }

  return (
    <ul className="home-timeline">
      <SingleWordInput
        memberName={memberName}
        singleWordPlaceholder={singleWordPlaceholder}
        singleWord={singleWordState}
        updateSingleWord={updateSingleWord}
        changeSingleWord={changeSingleWord}
      />

      {isLoading && <Loading className="loading" type="spin" color="#007559" />}
      {!isLoading &&
        timelineCards.map((timelineCard, index) => {
          switch (timelineCard.timelineCardType) {
            case 'JOIN':
              // eslint-disable-next-line no-case-declarations
              const joinMember = timelineCard.joinCard?.member
              return (
                joinMember && (
                  <JoinCard
                    key={joinMember.memberId}
                    joinMember={joinMember}
                    timelineCard={timelineCard}
                    myTimelineReactionMemberQuery={
                      myTimelineReactionMemberQuery
                    }
                    onSendMessage={sendMessage}
                  />
                )
              )

            case 'UPDATE_SINGLE_WORD':
              // eslint-disable-next-line no-case-declarations
              const updateSingleWordMember =
                timelineCard.updateSingleWordCard?.member
              return (
                updateSingleWordMember && (
                  <UpdateSingleWordCard
                    key={index}
                    timelineCard={timelineCard}
                    myTimelineReactionMemberQuery={
                      myTimelineReactionMemberQuery
                    }
                    onSendMessage={sendMessage}
                  />
                )
              )
            case 'UPDATE_CUBE':
            case 'string': // mockの場合
              return (
                <>
                  <UpdateCubeCard
                    timelineCard={timelineCard}
                    memberId={memberId}
                    memberCubes={memberCubes}
                    index={timelineCard.timelineCardId}
                    onSendMessage={sendMessage}
                  />
                </>
              )
          }
        })}
    </ul>
  )
}
export default Timeline
