import React from 'react'
import { SimpleMemberQuery } from '../../services'

interface MemberProfileCardProps {
  member: SimpleMemberQuery
  className?: string
  singleWord?: string | undefined
}

const MemberProfileCard: React.FC<MemberProfileCardProps> = ({
  member,
  className,
  singleWord,
}) => {
  return (
    <div className={`home-timeline__card-under ${className}`}>
      {(singleWord || member.singleWord) && (
        <div className="home-timeline__card-under-comment">
          <span>{singleWord || member.singleWord}</span>
        </div>
      )}
      <div className="home-timeline__card-under-member">
        <div className="home-timeline__card-under-avater">
          <img
            src={
              member.profileImageUrl
                ? member.profileImageUrl
                : '/icons/avatar-sample.png'
            }
            alt="プロフィール画像"
            width={64}
            height={64}
          />
        </div>
        <div className="home-timeline__card-under-profile">
          <div className="home-timeline__card-under-name">
            {member.memberName}
          </div>
          <div className="home-timeline__card-under-position">
            {member.position?.positionName}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MemberProfileCard
