import React from 'react'
import { Link } from 'react-router-dom'
import { MemberQuery } from '../../services'
import MemberCard from '../MemberCard'

interface MemberListProps {
  members: MemberQuery[]
  title: string
  targetMember?: MemberQuery
  linkTo: string
}

const MemberList: React.FC<MemberListProps> = ({
  members,
  title,
  targetMember,
  linkTo,
}) => {
  return (
    <div>
      <div className="member-page_header-wrapper">
        <h3 className="member_details-page__lead">{title}</h3>
        <Link to={linkTo} className="home-sidebar__more-link">
          もっとみる
        </Link>
      </div>
      {targetMember && (
        <p className="member_details-page__sub-lead">
          {targetMember.memberName}さんと共通点の多いメンバー
        </p>
      )}
      <ul className="related-members__list">
        {members.map(member => (
          <MemberCard
            key={member.memberId}
            member={member}
            className="related-members-list__item"
            cubeListClassName="cubes card_list-m__list"
            cubeItemClassName="card_list-m__item"
          />
        ))}
      </ul>
    </div>
  )
}

export default MemberList
