import React, { FC, useEffect, useState } from 'react'
import {
  ReactionQuery,
  TimelineCardQuery,
  TimelineReactionControllerService,
  TimelineReactionMemberQuery,
} from '../../services'
import apiErrorHandler from '../../api/apiErrorHandler'
import { toast } from 'react-toastify'
import { ReactionDetailModal } from '../../modals/reactionDetailModal'
import { useModal } from '../../contexts/modalContext'

interface Props {
  timelineCard: TimelineCardQuery
  myTimelineReactionMemberQuery: TimelineReactionMemberQuery
}

export const ToggleReactionButton: FC<Props> = ({
  timelineCard,
  myTimelineReactionMemberQuery,
}) => {
  const { openModal } = useModal()
  const reactionChoices = [
    'THUMBS_UP',
    'HEART',
    'SMILE_FACE',
    'GRINNING_SQUINTING_FACE',
    'LOUDLY_CRYING_FACE',
  ]

  // リアクション選択肢の表示/非表示
  const [isVisibleReactionChoises, setVisibleReactionChoises] = useState(false)

  // リアクション情報
  const [timelineReaction, setTimelineReaction] = useState(
    timelineCard.reaction,
  )

  const [reactionCount, setReactionCount] = useState(0)
  const [selectedReactionType, setSelectedReactionType] = useState(
    timelineReaction ? timelineReaction.sentReactionType : '',
  )

  useEffect(() => {
    setReactionCount(getReactedMembers().length)
  })

  // リアクション送信
  const toggleTimelineReaction = (
    timelineId: number,
    timelineReactionType: string,
  ) => {
    TimelineReactionControllerService.toggle({
      timelineCardId: timelineId,
      reactionType: timelineReactionType,
    })
      .then(() => {
        // 初リアクションの場合
        if (!selectedReactionType) {
          toast.success('リアクションを送信しました')
          setReactionCount(reactionCount + 1)
          setSelectedReactionType(timelineReactionType)
          toggleState(timelineReactionType, true)
          return
        }

        if (
          selectedReactionType &&
          selectedReactionType !== timelineReactionType
        ) {
          // リアクション変更の場合
          toast.success('リアクションを送信しました')
          setSelectedReactionType(timelineReactionType)
          changeState(selectedReactionType, timelineReactionType)
        } else {
          // リアクション取り消しの場合
          toast.success('リアクションを取り消しました')
          setReactionCount(reactionCount - 1)
          setSelectedReactionType('')
          toggleState(timelineReactionType, false)
        }
      })
      .catch(apiErrorHandler)
  }

  const toggleVisibleReactionChoises = () => {
    setVisibleReactionChoises(!isVisibleReactionChoises)
  }

  const reactionCountMessage = (): string => {
    if (selectedReactionType && reactionCount === 1) {
      return `<strong>あなた</strong>がリアクションしました`
    } else if (selectedReactionType && reactionCount > 1) {
      return `<strong>あなた</strong>と<strong>他${
        reactionCount - 1
      }人</strong>がリアクションしました`
    } else if (!selectedReactionType && reactionCount >= 1) {
      return `<strong>${reactionCount}人</strong>がリアクションしました`
    } else {
      return ''
    }
  }

  const getReactedMembers = () => {
    return timelineReaction?.sentMemberMap
      ? Object.values(timelineReaction?.sentMemberMap).flat()
      : []
  }

  // Stateのリアクションを切り替え
  const toggleState = (timelineReactionType: string, add: boolean) => {
    const newTimelineReaction = {
      ...timelineReaction,
      reactionSentMemberMap: {
        ...timelineReaction?.sentMemberMap,
        [timelineReactionType]: add
          ? [
              ...(timelineReaction?.sentMemberMap[timelineReactionType] || []),
              myTimelineReactionMemberQuery,
            ]
          : timelineReaction?.sentMemberMap[timelineReactionType]?.filter(
              member =>
                member.memberId !== myTimelineReactionMemberQuery.memberId,
            ),
      },
    } as ReactionQuery
    setTimelineReaction(newTimelineReaction)
  }

  // Stateのリアクションを変更
  const changeState = (
    oldTimelineReactionType: string,
    newTimelineReactionType: string,
  ) => {
    const newTimelineReaction = {
      ...timelineReaction,
      reactionSentMemberMap: {
        ...timelineReaction?.sentMemberMap,
        [oldTimelineReactionType]: timelineReaction?.sentMemberMap[
          oldTimelineReactionType
        ]?.filter(
          member => member.memberId !== myTimelineReactionMemberQuery.memberId,
        ),
      },
    } as ReactionQuery
    newTimelineReaction.sentMemberMap[newTimelineReactionType] = [
      ...(timelineReaction?.sentMemberMap[newTimelineReactionType] || []),
      myTimelineReactionMemberQuery,
    ]
    setTimelineReaction(newTimelineReaction)
  }

  return (
    <div className="toggle-reaction-container">
      <div className="toggle-reaction">
        <span className="toggle-reaction__balloon">
          クリックしてリアクションする
        </span>
        <button
          className="toggle-reaction__button"
          onClick={event => {
            event.preventDefault()
            event.stopPropagation()
            toggleVisibleReactionChoises()
          }}
        >
          <div
            className={`toggle-reaction__button__choices ${
              isVisibleReactionChoises ? 'isVisible' : ''
            }`}
          >
            {reactionChoices.map((reaction, index) => (
              <button
                key={index}
                onClick={event => {
                  event.preventDefault()
                  event.stopPropagation()
                  toggleTimelineReaction(timelineCard.timelineCardId, reaction)
                  toggleVisibleReactionChoises()
                }}
              >
                <img src={`/images/reaction-${reaction}.svg`} />
              </button>
            ))}
          </div>
          <img
            className="toggle-reaction__button__icon"
            src={
              selectedReactionType
                ? `/images/reaction-${selectedReactionType}.svg`
                : '/images/reaction.svg'
            }
          />
        </button>
      </div>
      {(getReactedMembers().length > 0 || selectedReactionType) && (
        <div className="toggle-reaction__message">
          <ul className="toggle-reaction__message__avatars">
            {getReactedMembers()
              .slice(0, 4)
              .map((member, index) => (
                <li key={index}>
                  <img
                    src={
                      member.profileImageUrl
                        ? member.profileImageUrl
                        : '/icons/avatar-sample.png'
                    }
                  />
                </li>
              ))}
            {getReactedMembers().length > 4 && <span>...</span>}
          </ul>
          <button
            className="toggle-reaction__message__link"
            onClick={event => {
              event.preventDefault()
              event.stopPropagation()
              openModal(ReactionDetailModal, {
                timelineReaction: timelineReaction,
              })
            }}
            dangerouslySetInnerHTML={{
              __html: reactionCountMessage(),
            }}
          ></button>
        </div>
      )}
    </div>
  )
}
