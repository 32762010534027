import React, { FC, useEffect, useState } from 'react'
import { Head } from '../../layouts/head'
import { Header } from '../../layouts/header'
import { SideNavi } from '../../layouts/sideNavi'
import { HeaderType } from '../../enums/HeaderType'
import apiErrorHandler from '../../api/apiErrorHandler'
import { MemberControllerService, MemberQuery } from '../../services'
import { Button } from '../../components/button'
import { CubeSendModal } from '../../modals/cubeSendModal'
import { useModal } from '../../contexts/modalContext'
import MemberList from '../../components/MemberList'
import { PickupMember } from '../../components/PickupMember'
import ProfileEditCard from '../../components/ProfileEditCard'
import Timeline from '../../components/timeLine'

export const Home: FC = () => {
  const [member, setMember] = useState({} as MemberQuery)
  const [recommendRecentMembers, setRecommendRecentMembers] = useState(
    [] as MemberQuery[],
  )
  const [recommendCommonMembers, setRecommendCommonMembers] = useState(
    [] as MemberQuery[],
  )
  const [showCoachMark, setShowCoachMark] = useState(false)
  const [showFirstTry, setShowFirstTry] = useState(false)
  const [showAbout, setShowAbout] = useState(false)
  const [showFirstTryAfter, setShowFirstTryAfter] = useState(false)

  const { openModal, closeModal } = useModal()

  useEffect(() => {
    // 0を指定することでログインユーザの情報を取得する
    MemberControllerService.getMember(0).then(setMember).catch(apiErrorHandler)
    MemberControllerService.getMembers(0, 'NEW', 3)
      .then(setRecommendRecentMembers)
      .catch(apiErrorHandler)
    MemberControllerService.getMembers(0, 'COMMON', 3)
      .then(setRecommendCommonMembers)
      .catch(apiErrorHandler)

    // オンボーディングの表示判定取得
    MemberControllerService.getOnboarding('MEMBER_ONBOARDING_COACH_MARK')
      .then(setShowCoachMark)
      .catch(apiErrorHandler)
    MemberControllerService.getOnboarding('MEMBER_ONBOARDING_FIRST_TRY')
      .then(setShowFirstTry)
      .catch(apiErrorHandler)
    MemberControllerService.getOnboarding('MEMBER_ONBOARDING_ABOUT')
      .then(setShowAbout)
      .catch(apiErrorHandler)
    MemberControllerService.getOnboarding('MEMBER_ONBOARDING_FIRST_TRY_AFTER')
      .then(setShowFirstTryAfter)
      .catch(apiErrorHandler)

    // オンボーディングの表示判定更新
    MemberControllerService.deleteOnboarding(
      'MEMBER_ONBOARDING_COACH_MARK',
    ).catch(apiErrorHandler)
    MemberControllerService.deleteOnboarding(
      'MEMBER_ONBOARDING_FIRST_TRY',
    ).catch(apiErrorHandler)
    MemberControllerService.deleteOnboarding('MEMBER_ONBOARDING_ABOUT').catch(
      apiErrorHandler,
    )
    MemberControllerService.deleteOnboarding(
      'MEMBER_ONBOARDING_FIRST_TRY_AFTER',
    ).catch(apiErrorHandler)
  }, [])

  // TODO デバッグ用のため削除する
  // setTimeout(() => {
  //   console.log('showCoachMark: ' + showCoachMark)
  //   console.log('showFirstTry: ' + showFirstTry)
  //   console.log('showAbout: ' + showAbout)
  //   console.log('showFirstTryAfter: ' + showFirstTryAfter)
  // }, 1000)

  return (
    <>
      <Head />
      <Header headerType={HeaderType.DEFAULT} />
      <SideNavi />
      <main className="main-sidenav">
        <div className="main_sidenav__inner">
          <Timeline
            memberId={member.memberId}
            memberName={member.memberName}
            singleWord={member.singleWord}
            profileImageUrl={member.profileImageUrl}
            positionName={member.position?.positionName}
            memberCubes={member.cubes}
            closeModal={closeModal}
          />

          <aside className="home-sidebar">
            <div className="home-sidebar__member">
              <h2 className="home-sidebar__title">
                今のあなたにおすすめのメンバー️
              </h2>
              <PickupMember
                recommendRecentMembers={recommendRecentMembers}
                recommendCommonMembers={recommendCommonMembers}
              />
              <section className="home-sidebar__section">
                <MemberList
                  members={recommendRecentMembers}
                  title="最近加入したメンバー"
                  linkTo={'/member-list'}
                />
              </section>
              <section className="home-sidebar__section">
                <MemberList
                  members={recommendCommonMembers}
                  title="あなたと共通点の多いメンバー"
                  linkTo={'/member-list?sort=COMMON'}
                />
              </section>
            </div>
            <ProfileEditCard />
          </aside>
        </div>
      </main>
      <Button
        className="home__cube-btn"
        onClick={() => openModal(CubeSendModal)}
        imageUrl="/images/cube_btn.svg"
      ></Button>
    </>
  )
}
