import { FC, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from '../../components/button'
import { exportCsv } from '../../lib/common'
import { Pagination } from '../../components/pagination'
import { CubeHistoryQuery, MemberRoleQuery } from '../../services'

interface TableProps {
  headContents: string[]
  cubeHistoryQueries: CubeHistoryQuery[]
  filterDate?: string
  memberRole: MemberRoleQuery
}

const CubeSummaryTable: FC<TableProps> = ({
  headContents,
  cubeHistoryQueries,
  filterDate,
  memberRole,
}) => {
  const query = new URLSearchParams(useLocation().search)
  const page = parseInt(query.get('page') as string) || 1
  const limit = 10
  const navigate = useNavigate()

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {}, [filterDate])
  return cubeHistoryQueries.length === 0 ? (
    <p className="table-contents_none">データが存在しません</p>
  ) : (
    <>
      {memberRole.memberRole === MemberRoleQuery.memberRole.ADMIN && (
        <div className="table-export">
          <Button
            className="btn-s"
            onClick={() => {
              exportCsv(
                'キューブ履歴_' + (filterDate ? filterDate : '全期間'),
                headContents,
                cubeHistoryQueries.map((cubeHistoryQuery, index) => [
                  (index + 1).toString(),
                  cubeHistoryQuery.member.memberName,
                  cubeHistoryQuery.giftMember.memberName,
                  cubeHistoryQuery.cube.cubeName,
                  cubeHistoryQuery.createdAt,
                ]),
              )
            }}
          >
            出力
          </Button>
        </div>
      )}
      <table className="table">
        <thead>
          <tr>
            {headContents.map((headContent, index) => (
              <th key={index}>{headContent}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {cubeHistoryQueries
            .slice((page - 1) * limit, page * limit)
            .map((cubeHistoryQuery, index) => (
              <tr key={index}>
                <td>{index + 1 + (page - 1) * limit}</td>
                <td>{cubeHistoryQuery.member.memberName}</td>
                <td>{cubeHistoryQuery.giftMember.memberName}</td>
                <td>{cubeHistoryQuery.cube.cubeName}</td>
                <td>{cubeHistoryQuery.createdAt}</td>
              </tr>
            ))}
        </tbody>
      </table>
      <Pagination
        totalPage={Math.ceil(cubeHistoryQueries.length / limit)}
        currentPage={page}
        goNext={() => navigate(`?page=${page + 1}`)}
        goBack={() => navigate(`?page=${page - 1}`)}
        goPage={(page: number) => navigate(`?page=${page}`)}
      />
    </>
  )
}

export default CubeSummaryTable
