import React, { FC, useEffect, useState } from 'react'
import { Head } from '../../layouts/head'
import { Header } from '../../layouts/header'
import { SideNavi } from '../../layouts/sideNavi'
import apiErrorHandler from '../../api/apiErrorHandler'
import {
  ApiError,
  MemberControllerService,
  MemberLogoutControllerService,
  MemberQuery,
  MemberWorkspaceControllerService,
  MemberWorkspaceQuery,
  WorkspaceSwitchingForm,
} from '../../services'
import { CubeExtractModal } from '../../modals/cubeExtractModal'
import { useModal } from '../../contexts/modalContext'
import { CareerEditModal } from '../../modals/careerEditModal'
import { ProfileEditModal } from '../../modals/profileEditModal'
import { ContactInfoEditModal } from '../../modals/contactInfoEditModal'
import { BusinessContentEditModal } from '../../modals/businessContentEditModal'
import { getCareerInfo } from '../../lib/common'
import { SafeParagraph } from '../../components/safeParagraph'
import { CubeSendModal } from '../../modals/cubeSendModal'
import { BasicInfo } from '../../components/BasicInfo'
import { CareerInfo } from '../../components/CareerInfo'
import { CubeList } from '../../components/cube/cubeList'
import useScrollToTop from '../../hooks/useScrollToTop'

export const Profile: FC = () => {
  const [member, setMember] = useState({} as MemberQuery)
  const [careerInfo, setCareerInfo] = useState<string[]>([])
  const { openModal } = useModal()

  const [workspaces, setWorkspaces] = useState<MemberWorkspaceQuery[]>([])
  const [workspaceId, setWorkspaceId] = useState<string>('')
  useScrollToTop()
  useEffect(() => {
    MemberControllerService.getMember(0)
      .then(res => {
        setMember(res)
        setCareerInfo(getCareerInfo(res.careers))
      })
      .catch(apiErrorHandler)

    MemberWorkspaceControllerService.getWorkspaces()
      .then(res => {
        setWorkspaces(res)
        if (res.length > 1) {
          MemberControllerService.getLoggedInMember()
            .then(loggedInMember => {
              setWorkspaceId(loggedInMember.workspaceId)
            })
            .catch(apiErrorHandler)
        }
      })
      .catch(apiErrorHandler)
  }, [openModal])

  const switchWorkspace = (event: React.ChangeEvent<HTMLSelectElement>) => {
    MemberWorkspaceControllerService.switchWorkspace({
      workspaceId: event.target.value,
    } as WorkspaceSwitchingForm)
      .then(res => {
        if (res.isFirstLogin) {
          window.location.href = '/sign-up'
        } else {
          window.location.href = '/'
        }
      })
      .catch(apiErrorHandler)
  }

  const logout = async () => {
    try {
      await MemberLogoutControllerService.logout()
      window.location.href = '/login'
    } catch (err) {
      await apiErrorHandler(err as ApiError)
    }
  }

  return (
    <>
      <Head />
      <Header />
      <SideNavi />
      <main className="main-sidenav profile_page-main-sidenav">
        <div className="profile_page__head">
          <div className="profile_page__flex-wrapper">
            <div className="profile-card">
              <div className="profile-card__img">
                <img
                  src={
                    member.profileImageUrl
                      ? member.profileImageUrl
                      : '/icons/avatar-sample.png'
                  }
                  alt="プロフィール画像"
                  onClick={() =>
                    openModal(ProfileEditModal, { member: member })
                  }
                />
              </div>

              <div className="profile-card__body">
                <h2
                  className="profile-card__name"
                  tabIndex={0}
                  onClick={() =>
                    openModal(ProfileEditModal, { member: member })
                  }
                >
                  {member.memberName}
                </h2>

                <p className="profile-card__name-ruby">
                  {member?.memberNameFurigana}
                </p>

                <div className="profile-card__position">
                  {member.position?.positionName}
                  {careerInfo.length > 0 && <br />}
                  {careerInfo.map((info, index) => (
                    <React.Fragment key={index}>
                      {index > 0 && <br />}
                      {info}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>

            <div className="profile_page__head-content">
              {member.singleWord}
            </div>
          </div>

          <div className="profile_page-comment-content__wrapper">
            <div className="profile_page-comment-content">
              <p className="profile_page__comment-text">{member.singleWord}</p>
            </div>
          </div>

          {/* TODO ディグラム診断 */}
          {/*
          <div className="profile_page__head-btn-wrapper">
            <button type="button" className="btn_profile_page-caution">
              ディグラム診断がまだ完了していません
            </button>
          </div>
          */}

          <div className="profile_page__head-avatar">
            <img
              src={
                member.profileImageUrl
                  ? member.profileImageUrl
                  : '/icons/avatar-sample.png'
              }
              alt="プロフィール画像"
              onClick={() => openModal(ProfileEditModal, { member: member })}
            />
          </div>
        </div>

        <div className="profile_page-profile-block-sp">
          <h2
            className="profile-card__name-sp"
            tabIndex={0}
            onClick={() => openModal(ProfileEditModal, { member: member })}
          >
            {member.memberName}
          </h2>

          <p className="profile-card__name-ruby-sp">
            {member.memberNameFurigana}
          </p>

          <div className="profile-card__position-sp">
            {member.position?.positionName}
            {careerInfo.length > 0 && <br />}
            {careerInfo.map((info, index) => (
              <React.Fragment key={index}>
                {index > 0 && <br />}
                {info}
              </React.Fragment>
            ))}
          </div>
        </div>

        <div className="main_sidenav__inner profile_page__inner">
          {workspaces.length > 1 && (
            <div className="profile_page__block">
              <div className="profile_page__content profile_page__workspace-content">
                <h3 className="profile-page__lead">ワークスペース切り替え</h3>
                <select
                  className="form__select"
                  value={workspaceId}
                  onChange={switchWorkspace}
                >
                  {workspaces.map((option, index) => (
                    <option
                      key={index}
                      value={option.workspaceId}
                      disabled={!option.workspaceName}
                    >
                      {option.workspaceName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
          <div className="profile_page__block">
            <div className="profile_page__content profile_page__cube-content">
              <h3
                className="profile-page__lead profile-page__lead-edit"
                tabIndex={0}
                onClick={() =>
                  openModal(BusinessContentEditModal, { member: member })
                }
              >
                主な業務内容
              </h3>
              <SafeParagraph
                className="profile__text"
                content={member.businessContent}
              />
            </div>
          </div>
          <div className="profile_page__block">
            <div className="profile_page__content profile_page__cube-content">
              <CubeList
                targetMember={member}
                titleClassName="profile-page__lead"
                listClassName="profile-cube__list"
                onAddClick={() => {
                  openModal(CubeSendModal, { memberId: member.memberId })
                }}
                onExtractClick={() => {
                  openModal(CubeExtractModal, { memberId: member.memberId })
                }}
                isProfile={true}
              />
            </div>
          </div>
          <div className="profile_page__block profile_page__block-flex">
            <div className="profile_page__content profile_page__career-content">
              <CareerInfo
                careers={member.careers}
                titleClassName="profile-page__lead"
                onAddClick={() =>
                  openModal(CareerEditModal, { careers: member.careers })
                }
                onEditClick={career =>
                  openModal(CareerEditModal, {
                    careers: member.careers,
                    target: career,
                  })
                }
                showAddButton={true}
              />
            </div>

            <div className="profile_page__content profile_page__base-profile-content">
              <BasicInfo
                member={member}
                titleClassName="profile-page__lead profile-page__lead-edit"
                onClick={() => openModal(ContactInfoEditModal, { member })}
              />
            </div>
          </div>
          <div className="profile_page__block">
            <div className="profile_page__content profile_page__mail-content">
              <h3 className="profile-page__lead">メール設定</h3>
              <div className="profile_page-mail-btn__wrapper">
                <button
                  className="profile_page__mail-btn"
                  onClick={() => {
                    window.location.href = '/setting'
                  }}
                >
                  メール設定を変更
                </button>
              </div>
            </div>
          </div>
          <button className="profile_page__logout" onClick={logout}>
            ログアウト
          </button>
        </div>
      </main>
    </>
  )
}
