import { FC } from 'react'
import { Button } from '../../components/button'

interface Props {
  closeModal: () => void
}
export const AboutCubeModal: FC<Props> = ({ closeModal }) => {
  return (
    <div
      className="about-cube__content modal__content"
      role="dialog"
      aria-modal="true"
      aria-labelledby="delete-talk__title"
    >
      <div className="modal-title_wrapper">
        <h1 className="modal-edit__title" id="delete-talk__title">
          キューブとは？
        </h1>
      </div>

      <div>
        <img className='about-cube__img' src="/images/about-cube-modal_img.png" alt="" width={272} height={151} />
        <p className='about-cube__text'>parksでは一人ひとりの個性のかけらを「キューブ」と呼んでいます。<br />
        あなたの興味・関心、スキル・専門知、パーソナリティをキューブとして登録しましょう。</p>
      </div>

      <div className="modal-edit-btn__wrapper">
        <Button className="btn_secondary-l" onClick={closeModal}>
          閉じる
        </Button>
      </div>
    </div>
  )
}
